import { Header } from '@/components/screen/layouts/Header';
import { BaseStyle } from '@/components/ui/BaseStyle';
import styled, { createGlobalStyle } from 'styled-components';
import { NoSidebarLayout } from './NoSidebarLayout';
import { WithSidebarLayout } from './WithSidebarLayout';

const headerHeight = '40px';

const GlobalStyle = createGlobalStyle`
  ${BaseStyle}
  html {
    background-color: #EFF1F4;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  padding-top: ${headerHeight};
`;

export type BaseLayoutProps = {
	sidebar?: boolean;
	children: React.ReactNode;
};

export const BaseLayout = ({
	children,
	sidebar = false,
}: BaseLayoutProps): JSX.Element => {
	return (
		<>
			<GlobalStyle />
			<Wrapper data-testid="base-layout">
				<Header />
				{sidebar ? (
					<WithSidebarLayout>{children}</WithSidebarLayout>
				) : (
					<NoSidebarLayout>{children}</NoSidebarLayout>
				)}
			</Wrapper>
		</>
	);
};
