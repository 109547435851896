export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

// MFCプロダクト共通ルール
// @see https://moneyforward.kibe.la/notes/151283
type MfcDataLayerFields = {
	office_identification_code?: string;
	office_type?: 'corporate' | 'individual';
};

export type DataLayerObject = {
	[K: string]: string | undefined;
	user_id?: string;
	event?: string;
	page?: string;
} & MfcDataLayerFields;

export type DataLayer = DataLayerObject[];

declare const window: Window['window'] & {
	dataLayer?: DataLayer;
};

export const dataLayerPush = (data: DataLayerObject) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(data);
};

// dataLayerの情報をresetする指示をGTMに伝える e.g.ページ遷移
export const dataLayerReset = (): void => {
	window.dataLayer = window.dataLayer || [];

	// @ts-expect-error resetの場合だけfunctionを許可
	window.dataLayer.push(function (): void {
		// @ts-expect-error gtagjsで定義されている
		// @see https://developers.google.com/gtagjs/devguide/datalayer#reset
		// dataLayerの配列を0に初期化するわけではない
		this.reset();
	});
};

export const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`;

export const gtmNoscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

export const CLICK_VIEW_ALL_TRANSACTION_HISTORY_BUTTON_HOME_SCREEN =
	'click-view-all-transaction-history-button-home-screen';

export const CLICK_MONTHLY_BALANCE_BUTTON_HOME_SCREEN =
	'click-monthly-balance-button-home-screen';
