import { useDestroySessionMutation } from '@/graphql';
import { clearCookie } from '@/lib/cookie';
import { dataLayerReset } from '@/lib/gtm';
import { useRouter } from 'next/router';
import { useGlobalNotification } from './useGlobalNotification';
import { useHandleUnknownError } from './useHandleUnknownError';

export type UseLogoutOptions = { disableRedirect: boolean };

export type UseLogoutReturn = { logout: () => Promise<void> };

export const useLogout = (options?: UseLogoutOptions): UseLogoutReturn => {
	const router = useRouter();
	const { resetGlobalNotification } = useGlobalNotification();
	const handleUnknownError = useHandleUnknownError();
	const [destroySession, { client }] = useDestroySessionMutation({
		context: { withoutOfficeIdHeader: true },
	});
	const { disableRedirect } = { ...options };
	const logout = async () => {
		try {
			await destroySession();
			resetGlobalNotification();
			// MEMO: this clearCookie includes clear of lastLoginOfficeId
			clearCookie();
			await client.clearStore();

			// Reset data layer for GTM
			dataLayerReset();

			if (disableRedirect) return;

			await router.push('/login');
		} catch (error) {
			// Handle unauthenticated error (Basically, payload is {success: true}.)
			handleUnknownError(error);
		}
	};

	return { logout };
};
