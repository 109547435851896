import styled from 'styled-components';

export type Props = {
	children: React.ReactNode;
	image: JSX.Element;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 32px;
  display: inline-block;
  white-space: pre-line;
`;

export const ActivateTitle = ({ children, image }: Props): JSX.Element => (
	<Wrapper>
		{image}
		<Heading>{children}</Heading>
	</Wrapper>
);
