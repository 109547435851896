import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { MiddleNameEnData } from './schema';

export const MiddleNameEnInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<MiddleNameEnData>();

	return (
		<div>
			<TextField
				aria-label="ミドルネーム（英語表記）"
				{...register('middleNameEn')}
				placeholder="ミドルネーム（任意）"
			/>
			{errors.middleNameEn && (
				<FormError>{errors.middleNameEn.message}</FormError>
			)}
		</div>
	);
};
