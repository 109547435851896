import { TenantTypeEnum, useCurrentOfficeTenantTypeQuery } from '@/graphql';
import type { ApolloError } from '@apollo/client';
import { useApiError } from './useApiError';

export const useTenantType = (): {
	isCorporate: boolean;
	loading: boolean;
	error: undefined | ApolloError;
} => {
	const { handleQueryError } = useApiError();
	const { data, loading, error } = useCurrentOfficeTenantTypeQuery({
		onError: handleQueryError,
	});
	const isCorporate =
		data?.currentOffice.tenantType === TenantTypeEnum.Corporate;

	return { isCorporate, loading, error };
};
