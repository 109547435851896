import { Sidebar as UiSidebar } from '@/components/ui/Sidebar';
import { RoleEnum } from '@/graphql';
import { useRole } from '@/hooks/useRole';
import { AdminSidebarItems } from './AdminSidebarItems';
import { EmployeeSidebarItems } from './EmployeeSidebarItems';

export const Sidebar = (): JSX.Element => {
	const { role } = useRole();

	return (
		<UiSidebar>
			{role === RoleEnum.Admin && <AdminSidebarItems />}
			{role === RoleEnum.Employee && <EmployeeSidebarItems />}
		</UiSidebar>
	);
};
