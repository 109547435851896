import { useApolloClient } from '@apollo/client';
import magnifyingGlass from '@images/icons/magnifyingGlass.svg';
import { useController, useWatch } from 'react-hook-form';

import { Combobox, type Option } from '@/components/new/Combobox/Combobox';
import { FormError } from '@/components/ui/FormError';
import { Image } from '@/components/ui/Image';
import {
	CurrentOfficeTenantUsersDocument,
	type CurrentOfficeTenantUsersQuery,
	type CurrentOfficeTenantUsersQueryVariables,
	useCurrentOfficeTenantUsersQuery,
} from '@/graphql';

export const TenantUserInput = (): JSX.Element => {
	const { query } = useApolloClient();
	const {
		field,
		fieldState: { error },
	} = useController({ name: 'tenantUser' });

	const tenantUser = useWatch({ name: 'tenantUser' });

	const { data: users } = useCurrentOfficeTenantUsersQuery({
		variables: {
			page: 1,
			perPage: 50,
		},
	});

	const parseOptions = (users: CurrentOfficeTenantUsersQuery | undefined) => {
		if (!users?.currentOffice.tenantUsers) return [];

		return users.currentOffice.tenantUsers.map((user) => ({
			label: `${user.displayName} ${user.email}`,
			value: user.uid,
		}));
	};

	const handleSearch = async (input: string) => {
		try {
			const { data } = await query<
				CurrentOfficeTenantUsersQuery,
				CurrentOfficeTenantUsersQueryVariables
			>({
				query: CurrentOfficeTenantUsersDocument,
				variables: {
					displayName: input,
				},
			});

			const options: Option[] = (data.currentOffice.tenantUsers ?? []).map<{
				label: string;
				value: string;
			}>((tenantUser) => ({
				label: `${tenantUser.displayName} ${tenantUser.email}`,
				value: tenantUser.uid,
			}));

			return options;
		} catch (_e) {
			return [];
		}
	};

	return (
		<>
			<Combobox
				value={tenantUser ? tenantUser : null}
				setValue={field.onChange}
				labelName="tenantUser"
				placeholder="表示名でユーザーを検索"
				defaultOptions={parseOptions(users)}
				defaultValue={tenantUser ? tenantUser.label : ''}
				loadingMessage="検索しています"
				noOptionsMessage="ユーザーが見つかりませんでした"
				indicatorImage={<Image src={magnifyingGlass} width={12} height={12} />}
				handleSearch={handleSearch}
			/>
			{error && <FormError>ユーザーを指定してください</FormError>}
		</>
	);
};
