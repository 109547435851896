import {
	type CurrentUserIdQuery,
	useCurrentOfficeAndUserIdQuery,
} from '@/graphql';
import { ErrorBoundary, Provider, useRollbarPerson } from '@rollbar/react';

// memo: https://docs.rollbar.com/docs/rollbarjs-telemetry
type EventObjectType = {
	level: 'debug' | 'info' | 'warning' | 'error' | 'critical';
	type: string;
	timestamp_ms: number;
	body: object;
	source: string;
	uuid?: string;
};

const token = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN;

// https://docs.rollbar.com/docs/rollbarjs-configuration-reference
const rollbarConfig = {
	accessToken: token,
	environment: process.env.NEXT_PUBLIC_APP_ENV,
	captureUncaught: true, // catchできなかったエラーもrollbarに通知
	captureUnhandledRejections: true, // handleできてないPromise.rejectをrollbarに通知
	enabled: !!token, // rollbarの使用可否
	payload: {
		client: {
			// https://docs.rollbar.com/docs/source-maps
			javascript: {
				source_map_enabled: true,
				code_version: process.env.BUILD_ID,
				guess_uncaught_frames: true,
			},
		},
		person: {
			// keyはrollbar側で固定されている
			id: undefined, // office.id
			username: undefined, // user.id
		},
	},
	filterTelemetry: (e: EventObjectType) => {
		return e.type === 'log' && e.level !== 'error'; // filter only logs except for error level
	},

	// biome-ignore lint/suspicious/noExplicitAny: Rollbar does not provide types for this function
	checkIgnore(_: boolean, _args: any, payload: any) {
		return (payload?.body?.trace?.exception?.description ?? '').includes(
			'Script error',
		);
	},
	scrubTelemetryInputs: true, // scrub all telemetry inputs
	// https://docs.rollbar.com/docs/rollbarjs-configuration-reference
	// Referred to filter_parameters in Wallet API.
	scrubFields: [
		'password',
		'phoneNumber',
		'activationCode',
		'department',
		'departmentName',
		'tradeName',
		'corporateName',
		'destinationName',
		'destinationDepartmentName',
		'name',
		'postalCode',
		'prefectureCode',
		'city',
		'street',
		'buildingName',
		'otherTerminatedReason',
		'pin',
		'birthDate',
		'officeCardName',
		'tenantUser',
		'code',
		'state',
		'login_hint',
		'token',
		'email',
	],
};

const FetchUserInfo = ({
	isFetchSkip,
	children,
}: {
	isFetchSkip: boolean;
	children: React.ReactNode;
}): JSX.Element => {
	// 以下の理由によりエラーハンドリングはしない
	// ・ログイン前の画面でも実行されてエラーが発生する
	// ・ユーザに関係するものではない
	// ・ここで発生したエラーを知る必要はない
	const { data } = useCurrentOfficeAndUserIdQuery({
		// ログインしていない場合はskipする
		skip: isFetchSkip,
		onError: () => {
			// do nothing
		},
	});

	useRollbarPerson({
		id: data?.currentOffice.id,
		username: data?.currentUser.id,
	});

	return <>{children}</>;
};

export const Logger = ({
	user,
	children,
}: {
	children: React.ReactNode;
	user?: CurrentUserIdQuery;
}): JSX.Element => {
	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary>
				<FetchUserInfo isFetchSkip={!user?.currentUser.id}>
					{children}
				</FetchUserInfo>
			</ErrorBoundary>
		</Provider>
	);
};
