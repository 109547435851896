import {
	Close,
	Content,
	DialogClose,
	type DialogContentProps,
	DialogDescription,
	type DialogTitleProps,
	DialogTrigger,
	Overlay,
	Portal,
	Root,
	Title,
} from '@radix-ui/react-dialog';

import crossCloseIcon from '@/assets/images/icons/crossClose.svg';
import Image from 'next/image';
import type { ComponentProps } from 'react';
import styles from './styles.module.css';

const DialogRoot = Root;
type DialogSize = 'small' | 'medium' | 'large';

const DialogWindow = ({
	size,
	transparent = false,
	...props
}: DialogContentProps & { size: DialogSize; transparent?: boolean }) => {
	const backgroundClass = transparent
		? styles.dialogBackgroundTransparent
		: styles.dialogBackground;
	return (
		<Portal>
			<Overlay className={styles.dialogOverlay} />
			<Content
				className={`${styles.dialogContent} ${styles[size]} ${backgroundClass}`}
				{...props}
			/>
		</Portal>
	);
};

const DialogContents = (props: ComponentProps<'div'>) => {
	return <div {...props} />;
};

const DialogHeader = (props: DialogTitleProps) => {
	return (
		<div className={styles.header}>
			<Title className={styles.title} {...props} />
			<Close asChild>
				<button
					type="button"
					className={styles.closeButton}
					aria-label="閉じる"
				>
					<Image src={crossCloseIcon} width={24} height={24} />
				</button>
			</Close>
		</div>
	);
};

const DialogFooter = (props: ComponentProps<'div'>) => {
	return <div {...props} />;
};

export const Dialog = Object.assign(DialogRoot, {
	Trigger: DialogTrigger,
	Description: DialogDescription,
	Close: DialogClose,
	Window: DialogWindow,
	Header: DialogHeader,
	Contents: DialogContents,
	Footer: DialogFooter,
});
