import type { Corporation } from '@/graphql';
import {
	type Dispatch,
	type PropsWithChildren,
	type SetStateAction,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';

type RegistrationPageState = {
	isFromConfirmation: boolean;
	selectedCorporation?: Corporation;
};

const defaultState: RegistrationPageState = {
	isFromConfirmation: false,
	selectedCorporation: undefined,
};

type RegistrationPageContextType = {
	registrationPageState: RegistrationPageState;
	setRegistrationPageState: Dispatch<SetStateAction<RegistrationPageState>>;
};

const RegistrationPageStateContext = createContext<RegistrationPageContextType>(
	{
		registrationPageState: defaultState,
		setRegistrationPageState: () => {},
	},
);

export const RegistrationPageStateProvider = ({
	defaultValue = defaultState,
	children,
}: PropsWithChildren<{ defaultValue?: RegistrationPageState }>) => {
	const [registrationPageState, setRegistrationPageState] =
		useState<RegistrationPageState>(defaultValue);
	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const value = useMemo(
		() => ({ registrationPageState, setRegistrationPageState }),
		[registrationPageState, setRegistrationPageState],
	);

	return (
		<RegistrationPageStateContext.Provider value={value}>
			{children}
		</RegistrationPageStateContext.Provider>
	);
};

export const useRegistrationPageState = () => {
	const { registrationPageState, setRegistrationPageState } = useContext(
		RegistrationPageStateContext,
	);

	const setFieldsState = ({
		isFromConfirmation,
		selectedCorporation,
	}: Partial<RegistrationPageState>) => {
		setRegistrationPageState((prev) => ({
			isFromConfirmation: isFromConfirmation ?? prev.isFromConfirmation,
			selectedCorporation: selectedCorporation ?? prev.selectedCorporation,
		}));
	};

	return { registrationPageState, setFieldsState };
};
