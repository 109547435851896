import type { IncomingMessage, ServerResponse } from 'node:http';
import type { TypedTypePolicies } from '@/graphql';
import {
	ApolloClient,
	type ApolloClientOptions,
	InMemoryCache,
	type NormalizedCacheObject,
	createHttpLink,
	from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import { transformQueryResult } from './transform';

type ApolloContext = ResolverContext | null;

interface ResolverContext {
	req?: IncomingMessage;
	res?: ServerResponse;
}
interface InitializeApolloProps {
	initialState?: NormalizedCacheObject | null;
	ctx?: ApolloContext;
	officeId?: string | null;
}

const authLink = (ctx: ApolloContext) =>
	setContext((_, { headers }) => {
		if (typeof window !== 'undefined') {
			// CSR時はブラウザでcookieが付与されるので何もしない
			return { headers };
		}

		// SSR時はcookie付与してAPIを実行
		return {
			headers: {
				...headers,
				Cookie: ctx?.req?.headers?.cookie || '',
			},
		};
	});

const officeIdLink = (officeId: InitializeApolloProps['officeId']) =>
	setContext((_, { headers = {}, withoutOfficeIdHeader = false }) => {
		return {
			headers: {
				...headers,
				...(!withoutOfficeIdHeader && officeId && { 'X-OFFICE-ID': officeId }),
			},
		};
	});

const httpLink = createHttpLink({
	uri: `${process.env.NEXT_PUBLIC_CLOUD_WALLET_API_URL}/graphql`,
	headers: {
		Accept: 'application/json',
		'content-type': 'application/json',
	},
	credentials: 'include',
});

const typePolicies: TypedTypePolicies = {
	BeneficialOwner: {
		fields: {
			birthDate: {
				read(birthDate) {
					// transform if birthDate not null
					if (birthDate) {
						return transformQueryResult.iso8601Date(birthDate as string);
					}

					return birthDate;
				},
			},
			postalCode: {
				read(postalCode) {
					return transformQueryResult.postalCode(postalCode as string);
				},
			},
		},
	},
	UserIdentification: {
		keyFields: ['itemId'],
		fields: {
			birthDate: {
				read(birthDate) {
					return transformQueryResult.iso8601Date(birthDate as string);
				},
			},
			postalCode: {
				read(postalCode: string) {
					if (!postalCode) return '';
					return transformQueryResult.postalCode(postalCode);
				},
			},
		},
	},
	Tenant: {
		keyFields: ['uid'],
	},
	NotificationSetting: {
		keyFields: ['code'],
	},
	InvoiceRegistrationInformationForDisplay: {
		keyFields: ['number'],
	},
	// NOTE: Uncomment the following lines when we're ready
	// CorporateIdentification: {
	//   keyFields: ['itemId'],
	// },
	// AccountManager: {
	//   keyFields: ['itemId'],
	// },
};

export const createApolloClient = (
	ctx: ApolloContext,
	officeId: InitializeApolloProps['officeId'],
): ApolloClient<NormalizedCacheObject> => {
	return new ApolloClient({
		connectToDevTools: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
		ssrMode: typeof window === 'undefined',
		cache: new InMemoryCache({
			typePolicies,
		}),
		link: from([authLink(ctx), officeIdLink(officeId), httpLink]),
	});
};

export const initializeApollo = ({
	initialState = null,
	ctx = null,
	officeId = null,
}: InitializeApolloProps): ApolloClient<NormalizedCacheObject> => {
	const apolloClient = createApolloClient(ctx, officeId);
	if (initialState) apolloClient.cache.restore(initialState);

	return apolloClient;
};

export const useApollo = (
	initialState: NormalizedCacheObject,
	officeId?: string,
): ApolloClient<NormalizedCacheObject> => {
	return useMemo(
		() => initializeApollo({ initialState, officeId }),
		[initialState, officeId],
	);
};

export const createApolloClientForMiddleware = (
	headers: ApolloClientOptions<NormalizedCacheObject>['headers'],
): ApolloClient<NormalizedCacheObject> => {
	return new ApolloClient({
		connectToDevTools: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
		ssrMode: typeof window === 'undefined',
		cache: new InMemoryCache({
			typePolicies,
		}),
		uri: `${process.env.NEXT_PUBLIC_CLOUD_WALLET_API_URL}/graphql`,
		credentials: 'include',
		headers: {
			...headers,
			accept: 'application/json',
			'content-type': 'application/json',
		},
	});
};
