import styles from './styles.module.css';

type RowHeaderCellProps = {
	bold?: boolean;
	size?: 'small' | 'medium' | 'large';
	justify?: 'start' | 'center' | 'end';
	width?: string;
} & React.ThHTMLAttributes<HTMLTableCellElement>;

export const RowHeaderCell = ({
	bold = false,
	size = 'medium',
	justify = 'start',
	width,
	...rest
}: RowHeaderCellProps): JSX.Element => {
	const classNames = [
		styles.rowHeaderCell,
		styles[size],
		bold && styles.bold,
		styles[justify],
	]
		.filter(Boolean)
		.join(' ');
	// Overwrite the width of the cell with the width prop.
	const style = { width };
	return <th className={classNames} scope="row" style={style} {...rest} />;
};
