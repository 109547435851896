import { Button } from '@/components/new/Button';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	display: 'inline-block',
	'> button': {
		width: '160px',
	},
});

export const Upload = (): JSX.Element => (
	<ButtonWrapper>
		<Button type="submit" size="large">
			アップロード
		</Button>
	</ButtonWrapper>
);
