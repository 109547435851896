import type React from 'react';
import styles from './styles.module.css';

export type Props = {
	color: 'gray' | 'red' | 'green' | 'orange' | 'blue';
	outline?: boolean;
	children: React.ReactNode;
};

export const StatusLabel = ({ color, outline = false, children }: Props) => {
	const colorStyle = styles[color];
	const outlineStyle = outline ? styles.outline : styles.default;
	const classNames = `${styles.label} ${colorStyle} ${outlineStyle}`;

	return <span className={classNames}>{children}</span>;
};
