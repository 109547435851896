import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { ManualPinData } from './schema';

export type ManualPinInputProps = {
	isCorporate: boolean;
};

const Box = styled.div`
  margin-bottom: 2px;
`;

export const ManualPinInput = ({
	isCorporate,
}: ManualPinInputProps): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<ManualPinData>();

	return (
		<>
			<TextField
				aria-label="manualPin"
				{...register('pin')}
				placeholder="4桁の半角数字"
			/>
			<Box>
				<Text size="small" color="notes">
					同じ数字3つ以上/連番(9012,7654等){!isCorporate && '/生年月日'}
					は設定不可
				</Text>
			</Box>

			{errors.pin && <FormError>{errors.pin.message}</FormError>}
		</>
	);
};
