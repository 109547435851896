import {
	BeneficialOwnerClassificationEnum,
	BeneficialOwnerTypeEnum,
	Prefecture,
} from '@/graphql';
import { yup } from '@/lib/yup';

export type RegistrationBeneficialOwnerData = yup.Asserts<
	typeof registrationBeneficialOwnerSchema
>;

const registrationBeneficialOwnerSchema = yup
	.object({
		classification: yup
			.mixed<BeneficialOwnerClassificationEnum>()
			.oneOf(
				Object.values(BeneficialOwnerClassificationEnum),
				'選択してください',
			)
			.required('選択してください'),
		beneficialOwnerType: yup
			.mixed<BeneficialOwnerTypeEnum>()
			.oneOf(Object.values(BeneficialOwnerTypeEnum), '選択してください')
			.required('選択してください'),
		name: yup.string().name().max(150).trim().required(),
		nameKana: yup.string().nameKana().max(150).trim().required(),
		nameEn: yup.string().nameEn().max(150).trim().required(),
		birthDate: yup
			.string()
			.when('classification', {
				is: BeneficialOwnerClassificationEnum.Individual,
				// biome-ignore lint/suspicious/noThenProperty: TODO
				then: (schema) => schema.iso8601Date().required(),
				otherwise: (schema) => schema.length(0).nullable().default(null),
			})
			.nullable(),

		buildingName: yup.string().nullable(),
		city: yup.string().required(),
		postalCode: yup.string().postalCode().required(),
		prefectureCode: yup
			.mixed<Prefecture>()
			.oneOf(Object.values(Prefecture), '選択してください')
			.required('選択してください'),
		street: yup.string().required(),
		itemId: yup.string(), //UI上には表示されないが、更新時に必要なので画面表示時にqueryで取得してデータとしては持っている
		nationalityId: yup.string().required('選択してください'),
	})
	.required();

export type RegistrationBeneficialOwnersData = yup.Asserts<
	typeof registrationBeneficialOwnersSchema
>;

export const registrationBeneficialOwnersSchema = yup.object({
	beneficialOwners: yup
		.array()
		.of(registrationBeneficialOwnerSchema)
		.min(1)
		.max(10)
		.required(),
});
