import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	display: 'inline-block',
	marginRight: '16px',
	'> button': {
		width: '160px',
	},
});

export const ToActivate = (): JSX.Element => (
	<ButtonWrapper>
		<Link href="/activate">
			<Button type="button" size="large">
				有効化画面へ進む
			</Button>
		</Link>
	</ButtonWrapper>
);
