import { forwardRef } from 'react';
import { useDropdown } from '../DropdownContext';
import styles from './styles.module.css';

type Props = {
	children?: React.ReactNode;
	role?: string;
	ariaLabel?: string;
	isKeepOpen?: boolean;
};

export const DropdownActionItem = forwardRef<HTMLDivElement, Props>(
	({ isKeepOpen, ...props }, ref) => {
		const { setIsActive } = useDropdown();
		const handleClick = () => {
			if (isKeepOpen) return;
			setIsActive(false);
		};
		return (
			<div
				className={styles.actionItem}
				onClick={handleClick}
				ref={ref}
				{...props}
			/>
		);
	},
);

DropdownActionItem.displayName = 'Dropdown.ActionItem';
