import { useGlobalMessage } from '@/hooks/useGlobalMessage';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { RouteHistoryContext } from './RouteHistoryProvider';

export const RouteEventHandler = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const router = useRouter();
	const { pushRoute } = useContext(RouteHistoryContext);
	const { resetGlobalNotification } = useGlobalNotification();
	const { resetGlobalMessage } = useGlobalMessage();

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			pushRoute(url);
			// Reset GlobalMessage and GlobalNotification because the message on the previous page will continue to be displayed unless reset by page transition.
			resetGlobalNotification();
			resetGlobalMessage();
		};

		router.events.on('routeChangeStart', handleRouteChange);

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, []);

	return <>{children}</>;
};
