import { type RoleEnum, useCurrentOfficeMemberRoleQuery } from '@/graphql';
import type { ApolloError } from '@apollo/client';
import { useApiError } from './useApiError';

export const useRole = (): {
	role: RoleEnum | undefined;
	loading: boolean;
	error: undefined | ApolloError;
} => {
	const { handleQueryError } = useApiError();
	const { data, loading, error } = useCurrentOfficeMemberRoleQuery({
		onError: handleQueryError,
	});

	const role = data?.currentOfficeMember.role;

	return { role, loading, error };
};
