import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { Image } from '@/components/ui/Image';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import card from '@images/card.svg';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { FormError } from '../FormError';
import { ManualPinInput } from '../inputs/ManualPin/ManualPinInput';
import type { RegistrationFirstCardOptionsData } from './schema';

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;

const CardWrapper = styled.section`
  margin-top: 1em;
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-left: 1em;
`;

const Box = styled.div`
  margin-bottom: 12px;
`;

export type Props = {
	corporate?: boolean;
};

export const RegistrationFirstCardOptions = ({
	corporate,
}: Props): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<RegistrationFirstCardOptionsData>();

	return (
		<Wrapper>
			<RegistrationTable header="オプション設定">
				<RegistrationTableRow
					title="カード暗証番号（PIN）"
					labelType="optional"
				>
					<Box>
						<Text>
							カード発行後の変更はできません。指定がない場合は自動で付与されます。カード発行後にWebまたはアプリからご確認ください。
						</Text>
					</Box>
					<ManualPinInput isCorporate={!!corporate} />
				</RegistrationTableRow>
				<RegistrationTableRow
					title={corporate ? 'カードに印字する法人名' : 'カードに印字する屋号'}
					labelType="optional"
				>
					<TextField
						placeholder="全角15文字、半角30文字以内 / 和文・英文どちらでも可"
						aria-label="officeCardName"
						{...register('officeCardName')}
					/>
					{errors.officeCardName && (
						<section data-testid="officeCardNameError">
							<FormError>{errors.officeCardName.message}</FormError>
						</section>
					)}
					<CardWrapper>
						<Image src={card} width={108} height={72} />
						<StyledText>
							カード裏面に{corporate ? '法人名' : '屋号'}
							を印字することができます (青枠)
						</StyledText>
					</CardWrapper>
				</RegistrationTableRow>
			</RegistrationTable>
		</Wrapper>
	);
};
