import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { useFormContext } from 'react-hook-form';
import type { FirstNameKanaData } from './schema';

export const FirstNameKanaInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<FirstNameKanaData>();

	return (
		<div>
			<TextField
				aria-label="firstNameKana"
				{...register('firstNameKana')}
				placeholder="例）タロウ"
			/>
			{errors.firstNameKana && (
				<FormError>{errors.firstNameKana.message}</FormError>
			)}
		</div>
	);
};
