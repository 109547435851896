import { FlashMessage } from '@/components/new/FlashMessage';
import { useGlobalMessage } from '@/hooks/useGlobalMessage';
import styled from 'styled-components';

const Box = styled.div`
  margin-bottom: 18px;
`;

export const GlobalMessage = (): JSX.Element | null => {
	const { type, message } = useGlobalMessage();
	if (!message) return null;

	return (
		<Box>
			<FlashMessage color={type}>{message}</FlashMessage>
		</Box>
	);
};
