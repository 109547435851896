import { forwardRef } from 'react';
import styled from 'styled-components';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const StyledTextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  height: auto;
  padding: 6px 5px;
  background-color: #ffffff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d8dd;
  color: #333333;
  font-size: 13px;
  box-sizing: border-box;
  ::placeholder {
    color: #999999;
  }
`;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({ ...rest }, ref) => <StyledTextArea ref={ref} {...rest} />,
);

TextArea.displayName = 'TextArea';
