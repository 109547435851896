type IconProps = {
	size?: number;
	color?: string;
};

export const HelpIcon = ({ size = 18, color = '#747474' }: IconProps) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
		<svg
			width={size}
			height={size}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM11.3025 8.1875L10.6275 8.8775C10.5706 8.93516 10.516 8.98899 10.4638 9.04042C10.0201 9.47739 9.75 9.74349 9.75 10.75C9.75 11.1642 9.41421 11.5 9 11.5C8.58579 11.5 8.25 11.1642 8.25 10.75V10.625C8.25 9.8 8.5875 9.05 9.1275 8.5025L10.0575 7.5575C10.335 7.2875 10.5 6.9125 10.5 6.5C10.5 5.675 9.825 5 9 5C8.175 5 7.5 5.675 7.5 6.5C7.5 6.91421 7.16421 7.25 6.75 7.25C6.33579 7.25 6 6.91421 6 6.5C6 4.8425 7.3425 3.5 9 3.5C10.6575 3.5 12 4.8425 12 6.5C12 7.16 11.73 7.76 11.3025 8.1875ZM10 13.5C10 14.0523 9.55229 14.5 9 14.5C8.44771 14.5 8 14.0523 8 13.5C8 12.9477 8.44771 12.5 9 12.5C9.55229 12.5 10 12.9477 10 13.5Z"
				fill={color}
			/>
		</svg>
	);
};
