import styled from 'styled-components';

export type Props = {
	step?: number;
	title: string;
	center?: boolean;
};

const Wrapper = styled.div<{ center: Props['center'] }>`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  ${({ center }) => center && 'justify-content: center'}
`;

const Heading = styled.h2`
  font-size: 20px;
  margin: 0;
  display: inline-block;
`;

export const RegistrationTitle = ({ title, center }: Props): JSX.Element => (
	<Wrapper center={center}>
		<Heading>{title}</Heading>
	</Wrapper>
);
