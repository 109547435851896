import { Table } from '@/components/new/Table';

export type Props = {
	header: React.ReactNode;
	children: React.ReactNode;
};

export const RegistrationTable = ({
	header,
	children,
	...rest
}: Props): JSX.Element => {
	return (
		<Table.Root {...rest}>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>{header}</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>{children}</Table.Body>
		</Table.Root>
	);
};
