import styled, { css } from 'styled-components';
import { fontColor, fontSize } from './variables';

type Size = keyof typeof fontSize;
type Color = keyof typeof fontColor;

export type Props = React.HTMLAttributes<HTMLSpanElement> & {
	size?: Size;
	color?: Color;
};

const StyledText = styled.span<Props>`
  ${({ size = 'middle', color = 'default' }) => css`
    font-size: ${fontSize[size]};
    color: ${fontColor[color]};
  `}
`;

export const Text = (props: Props): JSX.Element => {
	return <StyledText {...props} />;
};
