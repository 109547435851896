import { type ReactNode, createContext, useMemo, useState } from 'react';

type State = {
	notification: ReactNode;
	type: 'success' | 'error' | 'warning';
	isVisible: boolean;
};

export type GlobalNotificationContextType = State & {
	resetGlobalNotification: () => void;
	hideGlobalNotification: () => void;
	showSuccessNotification: (notification: State['notification']) => void;
	showErrorNotification: (notification: State['notification']) => void;
	showWarningNotification: (notification: State['notification']) => void;
};

export const GlobalNotificationContext =
	createContext<GlobalNotificationContextType>({
		notification: '',
		type: 'success',
		isVisible: false,
		resetGlobalNotification: () => {
			// This is a placeholder function that will be overridden by the provider
		},
		hideGlobalNotification: () => {
			// This is a placeholder function that will be overridden by the provider
		},
		showSuccessNotification: () => {
			// This is a placeholder function that will be overridden by the provider
		},
		showErrorNotification: () => {
			// This is a placeholder function that will be overridden by the provider
		},
		showWarningNotification: () => {
			// This is a placeholder function that will be overridden by the provider
		},
	});

export const GlobalNotificationProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const defaultValue: State = {
		notification: '',
		type: 'success',
		isVisible: false,
	};
	const [notification, setNotification] = useState<State>(defaultValue);

	const value = useMemo(() => {
		return {
			...notification,
			resetGlobalNotification: () => {
				setNotification(defaultValue);
			},
			hideGlobalNotification: () => {
				setNotification((prev) => ({
					...prev,
					isVisible: false,
				}));
			},
			showSuccessNotification: (notification: State['notification']) => {
				setNotification({
					notification,
					type: 'success',
					isVisible: true,
				});
			},
			showErrorNotification: (notification: State['notification']) => {
				setNotification({
					notification,
					type: 'error',
					isVisible: true,
				});
			},
			showWarningNotification: (notification: State['notification']) => {
				setNotification({
					notification,
					type: 'warning',
					isVisible: true,
				});
			},
		};
	}, [notification]);

	return (
		<GlobalNotificationContext.Provider value={value}>
			{children}
		</GlobalNotificationContext.Provider>
	);
};
