import styled from 'styled-components';
import { HowToUseStorylaneModal } from '../HowToUseStorylaneModal';

const headerHeight = '40px';

export type NoSidebarLayoutProps = {
	sidebar?: boolean;
	children: React.ReactNode;
};

const Main = styled.main`
  min-height: calc(100vh - ${headerHeight});
`;

export const NoSidebarLayout = ({
	children,
}: NoSidebarLayoutProps): JSX.Element => {
	return (
		<>
			<HowToUseStorylaneModal />
			<Main>{children}</Main>
		</>
	);
};
