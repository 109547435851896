import { BeneficialOwnerTypeEnum } from '@/graphql';

export const BeneficialOwnerTypeOptions = [
	{
		label: '50%超の議決権／収益の配当／財産の分配がある',
		value: BeneficialOwnerTypeEnum.MoreThanHalfPower,
	},
	{
		label: '25％超の議決権／収益の配当／財産の分配がある',
		value: BeneficialOwnerTypeEnum.MoreThanQuarterPower,
	},
	{
		label:
			'出資・融資取引その他を通じて事業活動に支配的な影響力を有する（大口債権者や創業者など）',
		value: BeneficialOwnerTypeEnum.DominantInfluencer,
	},
	{
		label: '法人を代表しその業務を執行する個人（代表取締役社長など）',
		value: BeneficialOwnerTypeEnum.Representative,
	},
];

export const getBeneficialOwnerTypeLabel = (
	beneficialOwnerType: BeneficialOwnerTypeEnum | undefined,
): string => {
	const result = BeneficialOwnerTypeOptions.find((option) => {
		return beneficialOwnerType === option.value;
	});
	return result?.label ?? '';
};
