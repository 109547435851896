import { CurrentOfficeContext } from '@/contexts/CurrentOfficeProvider';
import { useCreateOfficeAndMemberMutation } from '@/graphql';
import { useLogger } from '@/hooks/useLogger';
import { clearCookie, setLoginCookie } from '@/lib/cookie';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';
import {
	getGraphQLError,
	hasAuthorizationError,
	hasInternalServerError,
	hasNotInvitedError,
	hasOfficeNotFoundError,
	useApiError,
} from './useApiError';

export const useLoginOffice = (): {
	loginOffice: (id: string, destination?: string) => Promise<void>;
} => {
	const { logError } = useLogger();
	const router = useRouter();
	const { handleMutationError } = useApiError();
	const { setName, setTenantType, setEkycStatus } =
		useContext(CurrentOfficeContext);
	const [createOfficeAndMember, { loading }] = useCreateOfficeAndMemberMutation(
		{
			onError: (err) => {
				// if office is not found, redirect to /offices
				const isOfficesPage = router.pathname === '/offices';
				const graphqlError = getGraphQLError(err);
				if (hasOfficeNotFoundError(err) && !isOfficesPage) {
					logError('NotFoundError', graphqlError?.extensions.code);
					router.push('/offices');
					return;
				}
				if (hasNotInvitedError(err) && !isOfficesPage) {
					logError('NotInvitedError', graphqlError?.extensions.code);
					router.push('/offices');
					return;
				}
				if (hasAuthorizationError(err) && !isOfficesPage) {
					logError('AuthorizationError', graphqlError?.extensions.code);
					router.push('/offices');
					return;
				}
				if (hasInternalServerError(err) && !isOfficesPage) {
					logError('InternalServerError', graphqlError?.extensions.code);
					router.push('/offices');
					return;
				}

				handleMutationError(err);
			},
		},
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const loginOffice = useCallback(
		async (id: string, destination?: string) => {
			const destinationPath = destination && decodeURIComponent(destination);

			if (loading) return;

			// clear cookie before login to office
			// and after redirectPath was substitued
			clearCookie();

			await createOfficeAndMember({
				variables: { id },
				onCompleted: (data) => {
					if (!data?.createOfficeAndMember) {
						logError('createOfficeAndMemberが存在しません');
						return;
					}

					const {
						createOfficeAndMember: {
							isOfficeMemberActivated,
							officeId,
							officeName,
							tenantType,
							ekycStatus,
						},
					} = data;

					// MEMO: We use cookie to handle lastLoginOfficeId
					setLoginCookie(officeId);
					setTenantType(tenantType);
					setName(officeName);
					setEkycStatus(ekycStatus);

					if (!isOfficeMemberActivated) {
						// 規約同意していない場合
						router.push(
							destinationPath?.startsWith('/')
								? destinationPath
								: '/registration/terms',
						);
						return;
					}

					router
						.push(
							destinationPath?.startsWith('/')
								? destinationPath
								: '/login/redirect',
						)
						.then(() => router.reload());
				},
			});
		},
		[createOfficeAndMember, router],
	);

	return { loginOffice };
};
