import { Button } from '@/components/new/Button';
import { PlusIcon } from '@/components/screen/settings/destinations/Index/PlusIcon';
import {
	BeneficialOwnerClassificationEnum,
	BeneficialOwnerTypeEnum,
	Prefecture,
} from '@/graphql';
import { useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import { RegistrationBeneficialOwner } from './RegistrationBeneficialOwner';
import type { RegistrationBeneficialOwnersData } from './schema';

type Props = {
	prefectureCode?: Prefecture;
	addRemovedBeneficialOwnerIds: (id: string | undefined) => void;
};

const Wrapper = styled.div`
  display: grid;
  row-gap: 8px;
  margin-bottom: 8px;
`;

export const RegistrationBeneficialOwners = ({
	prefectureCode,
	addRemovedBeneficialOwnerIds,
}: Props): JSX.Element => {
	const { fields, append, remove } =
		useFieldArray<RegistrationBeneficialOwnersData>({
			name: 'beneficialOwners',
		});

	return (
		<>
			<Wrapper>
				{fields.map((item, index) => {
					const removeBeneficialOwner = () => {
						const confirmed = confirm('削除してもよろしいですか');
						if (!confirmed) return;
						remove(index);
						addRemovedBeneficialOwnerIds(item.itemId);
					};

					return (
						<RegistrationBeneficialOwner
							key={item.id}
							index={index}
							item={item}
							{...(fields.length > 1 && { removeBeneficialOwner })}
						/>
					);
				})}
			</Wrapper>
			{fields.length < 10 && (
				<Button
					aria-label="addBeneficialOwner"
					type="button"
					onClick={() =>
						append({
							classification: BeneficialOwnerClassificationEnum.Individual,
							beneficialOwnerType: BeneficialOwnerTypeEnum.MoreThanHalfPower,
							name: '',
							nameKana: '',
							nameEn: '',
							birthDate: '',
							buildingName: '',
							city: '',
							postalCode: '',
							prefectureCode: prefectureCode ?? Prefecture.Tokyo,
							street: '',
							itemId: undefined,
							nationalityId: 'JP',
						})
					}
					variant="outline"
				>
					<PlusIcon color="#3b7de9" />
					実質的支配者を追加する
				</Button>
			)}
		</>
	);
};
