import styled from 'styled-components';

export type TitleProps = {
	children: React.ReactNode;
};

const StyledTitle = styled.h1`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

export const Title = ({ children }: TitleProps): JSX.Element => (
	<StyledTitle>{children}</StyledTitle>
);

Title.displayName = 'NavigationBar.Title';
