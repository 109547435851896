import { yup } from '@/lib/yup';

export type FirstNameKanaData = yup.Asserts<typeof firstNameKanaSchema>;

export const firstNameKanaSchema = yup.object({
	firstNameKana: yup
		.string()
		.nameKana()
		.trim()
		.max(128, '128文字以内で入力してください')
		.required(),
});
