import { Table } from '@/components/new/Table';
import { AppStore } from '@/components/ui/AppStore';
import { RegistrationTable } from '@/components/ui/RegistrationTable';

export const RegistrationIdentificationApp = (): JSX.Element => (
	<RegistrationTable header="アプリをダウンロード">
		<Table.Row>
			<Table.Cell>
				<AppStore />
			</Table.Cell>
		</Table.Row>
	</RegistrationTable>
);
