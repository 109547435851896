import crossCloseIcon from '@/assets/images/icons/crossClose.svg';
import { Image } from '@/components/ui/Image';
import {
	Combobox as AriakitCombobox,
	ComboboxItem,
	ComboboxPopover,
	ComboboxProvider,
} from '@ariakit/react/combobox';
import { matchSorter } from 'match-sorter';
import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.css';

export type Option = {
	value: string;
	label: string;
};

type Props = {
	value?: Option;
	setValue: (value?: Option) => void;
	labelName: string;
	placeholder: string;
	loadingMessage: string;
	noOptionsMessage: string;
	defaultOptions?: Option[];
	defaultValue?: string;
	handleSearch?: (input: string) => Promise<Option[]>;
	indicatorImage?: JSX.Element;
};

export const Combobox = ({
	value,
	setValue,
	labelName,
	placeholder,
	loadingMessage,
	noOptionsMessage,
	defaultOptions,
	defaultValue,
	handleSearch,
	indicatorImage,
}: Props): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>(defaultValue ?? '');
	const [options, setOptions] = useState<Option[]>(defaultOptions || []);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const matches = useMemo(() => {
		if (searchValue === '') return defaultOptions ?? [];

		return matchSorter<Option>(options, searchValue, {
			keys: ['label'],
		});
	}, [options, searchValue, defaultOptions]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		if (isLoading) setIsLoading(false);
	}, [options]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		const timer = setTimeout(async () => {
			if (!handleSearch) return;

			setIsLoading(true);
			const res = await handleSearch(searchValue);
			setOptions(res);
		}, 300);

		return () => {
			clearTimeout(timer);
		};
	}, [searchValue]);

	return (
		<ComboboxProvider>
			<div className={styles.comboboxWrapper}>
				{value && (
					<button
						type="button"
						data-testid="combobox-delete-icon"
						className={styles.comboboxDeleteIcon}
						onClick={() => {
							setSearchValue('');
							setOptions(defaultOptions ?? []);
							setValue(undefined);
						}}
					>
						<Image src={crossCloseIcon} width={14} height={14} />
					</button>
				)}
				<span className={styles.comboboxSearchIcon}>{indicatorImage}</span>
				<AriakitCombobox
					readOnly={!!value}
					id="combobox"
					aria-label={labelName}
					value={value ? value.label : searchValue}
					className={styles.combobox}
					onInput={(e: React.FormEvent<HTMLInputElement>) => {
						if (e.currentTarget.value === '') {
							setValue(undefined);
							setOptions(defaultOptions ?? []);
						}
						setSearchValue(e.currentTarget.value);
					}}
					placeholder={placeholder}
				/>
			</div>

			<ComboboxPopover
				aria-controls="combobox-popover"
				id="combobox-popover"
				focusable
				autoFocusOnShow
				autoFocus
				gutter={-4}
				sameWidth
				className={styles.popover}
			>
				{/* biome-ignore lint/complexity/noUselessFragments: TODO */}
				<>
					{matches.length === 0 ? (
						<>
							{isLoading ? (
								<div className={styles.popoverInfo}>{loadingMessage}</div>
							) : (
								<div className={styles.popoverInfo}>{noOptionsMessage}</div>
							)}
						</>
					) : (
						matches.map((option: Option, index: number) => (
							<ComboboxItem
								focusable
								date-testid={`test-${option.value}`}
								id={`option-${option.value}`}
								tabIndex={index}
								autoFocus={index === 0}
								className={styles.comboboxItem}
								key={option.value}
								value={option.value}
								onClick={() => {
									setSearchValue(option.label);

									const userValue: Option = {
										label: option.label,
										value: option.value,
									};
									setValue(userValue);
								}}
							>
								{option.label}
							</ComboboxItem>
						))
					)}
				</>
			</ComboboxPopover>
		</ComboboxProvider>
	);
};

Combobox.displayName = 'Combobox';
