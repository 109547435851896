import { Dropdown } from '@/components/new/Dropdown';
import { Text } from '@/components/new/Text';
import { useCurrentOfficeMemberQuery, useOfficeMenuQuery } from '@/graphql';
import { HelpMenuSection } from './HelpMenu';
import { IconButton } from './IconButton';
import {
	OfficeInfoItem,
	OfficeSettingItem,
	OfficeSwitchItem,
} from './OfficeMenu';
import { UserMenuSection } from './UserMenu';

export const NavigationMenu = (): JSX.Element | null => {
	const {
		data: currentOfficeMemberData,
		error: currentOfficeMemberQueryError,
	} = useCurrentOfficeMemberQuery();
	const { data: officeMenuData, error: officeMenuQueryError } =
		useOfficeMenuQuery({
			fetchPolicy: 'network-only',
		});

	if (
		currentOfficeMemberQueryError ||
		!currentOfficeMemberData ||
		officeMenuQueryError ||
		!officeMenuData
	) {
		return null;
	}

	return (
		<Dropdown.Root
			width="235px"
			placement="right"
			anchor={<IconButton message="事業者、アカウントなど" type="more" />}
		>
			<Dropdown.Block>
				<OfficeInfoItem data={officeMenuData} />
				<Dropdown.Item>
					<Text>{currentOfficeMemberData.currentOfficeMember.displayName}</Text>
				</Dropdown.Item>
			</Dropdown.Block>

			<HelpMenuSection />

			<Dropdown.Block>
				<OfficeSettingItem />
				<OfficeSwitchItem />
			</Dropdown.Block>

			<UserMenuSection data={currentOfficeMemberData} />
		</Dropdown.Root>
	);
};
