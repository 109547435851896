import { Dropdown } from '@/components/new/Dropdown';
import { Text } from '@/components/new/Text';
import {
	CurrentRequestStatus,
	type OfficeMenuQuery,
	RoleEnum,
	useOfficeMenuQuery,
} from '@/graphql';
import { formatIdentificationCode } from '@/lib/string';
import styled from 'styled-components';
import { MenuLink } from './MenuLink';

const Box = styled.div`
  margin-bottom: 2px;
`;

export const OfficeMenu = (): JSX.Element | null => {
	const { data, error } = useOfficeMenuQuery();

	if (error || !data) {
		// TODO: エラー通知
		return null;
	}

	const { currentOffice, currentOfficeMember } = data;

	return (
		<Dropdown.Root
			toggleLabel={data.currentOffice.name}
			placement="right"
			width="312px"
		>
			<Dropdown.Block>
				<OfficeInfoItem data={data} />
				{currentOffice.currentRequestStatus ===
					CurrentRequestStatus.ActivatedCard &&
					currentOfficeMember.role === RoleEnum.Admin && <OfficeSettingItem />}
			</Dropdown.Block>
			<Dropdown.Block>
				<OfficeSwitchItem />
			</Dropdown.Block>
		</Dropdown.Root>
	);
};

export const OfficeInfoItem = ({
	data,
}: {
	data: OfficeMenuQuery;
}): JSX.Element | null => {
	const { currentOffice } = data;

	return (
		<Dropdown.Item>
			<Box>{currentOffice.name}</Box>
			<Text color="notes">
				事業者番号:
				{formatIdentificationCode(currentOffice.identificationCode)}
			</Text>
		</Dropdown.Item>
	);
};

export const OfficeSettingItem = (): JSX.Element => {
	return (
		<Dropdown.ActionItem>
			<MenuLink href="/settings/office">事業者設定</MenuLink>
		</Dropdown.ActionItem>
	);
};

export const OfficeSwitchItem = (): JSX.Element => {
	return (
		<Dropdown.ActionItem>
			<MenuLink href="/offices">事業者切替</MenuLink>
		</Dropdown.ActionItem>
	);
};
