import { yup } from '@/lib/yup';

export type FirstNameData = yup.Asserts<typeof firstNameSchema>;

export const firstNameSchema = yup.object({
	firstName: yup
		.string()
		.name()
		.trim()
		.max(128, '128文字以内で入力してください')
		.required(),
});
