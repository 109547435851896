import { css } from 'styled-components';

export const BaseStyle = css`
  html {
    margin: 0;
    padding: 0;
    font-family:
      'Noto Sans JP',
      -apple-system,
      BlinkMacSystemFont,
      Helvetica,
      Hiragino Sans,
      ヒラギノ角ゴ ProN W3,
      Hiragino Kaku Gothic ProN,
      Arial,
      Meiryo,
      sans-serif;
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #333;
    margin: 0;
  }
  textarea {
    font-family:
      'Noto Sans JP',
      -apple-system,
      BlinkMacSystemFont,
      Helvetica,
      Hiragino Sans,
      ヒラギノ角ゴ ProN W3,
      Hiragino Kaku Gothic ProN,
      Arial,
      Meiryo,
      sans-serif;
  }
`;
