import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { BirthDateData } from './schema';

const InputWrapper = styled.div`
  width: 260px;
`;

export const BirthDateInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<BirthDateData>();

	return (
		<div>
			<InputWrapper>
				<TextField
					aria-label="birthDate"
					{...register('birthDate')}
					placeholder="例）1960/06/06"
				/>
			</InputWrapper>
			{errors.birthDate && <FormError>{errors.birthDate.message}</FormError>}
		</div>
	);
};
