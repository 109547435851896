import { createContext, useContext } from 'react';

interface IDropdownContext {
	isActive: boolean;
	setIsActive: (flag: boolean) => void;
}

export const DropdownContext = createContext<IDropdownContext | undefined>(
	undefined,
);

export const useDropdown = (): IDropdownContext => {
	const context = useContext(DropdownContext);
	if (!context) {
		throw new Error(
			'This component must be used within a <Dropdown> component.',
		);
	}
	return context;
};
