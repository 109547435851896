import { Image } from '@/components/ui/Image';
import { Sidebar } from '@/components/ui/Sidebar';
import { useTenantType } from '@/hooks/useTenantType';
import IconCard from '@images/icons/sideCard.svg';
import IconGear from '@images/icons/sideGear.svg';
import IconHome from '@images/icons/sideHome.svg';
import IconReceiptLong from '@images/icons/sideReceiptLong.svg';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const NavItemText = styled.span`
  margin-left: 8px;
  position: relative;
  top: -1px;
`;

export const AdminSidebarItems = (): JSX.Element => {
	const { pathname } = useRouter();
	const { isCorporate } = useTenantType();

	return (
		<>
			<Sidebar.NavItemLink
				href="/home"
				current={
					!!pathname.match(
						/^\/home|^\/wallet(?!\/transactions|\/exports)|^\/postpay\/transactions/,
					)
				}
			>
				<Image src={IconHome} alt="" width={24} height={24} />
				<NavItemText>ホーム</NavItemText>
			</Sidebar.NavItemLink>

			<Sidebar.NavGroup
				label="取引"
				imageSrc={IconReceiptLong}
				current={
					!!pathname.match(/^\/(cards\/transactions|wallet\/transaction)/)
				}
			>
				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/cards\/transactions/)}
					href="/cards/transactions"
				>
					利用明細
				</Sidebar.NavGroupItemLink>
				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/wallet\/transactions/)}
					href="/wallet/transactions"
				>
					入出金履歴
				</Sidebar.NavGroupItemLink>
			</Sidebar.NavGroup>

			<Sidebar.NavItemLink
				href="/cards"
				current={!!pathname.match(/^\/cards(?!\/transactions)/)}
			>
				<Image src={IconCard} alt="" width={24} height={24} />
				<NavItemText>カード</NavItemText>
			</Sidebar.NavItemLink>

			<Sidebar.NavGroup
				label="設定"
				imageSrc={IconGear}
				current={!!pathname.match(/^\/settings\//)}
			>
				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/settings\/office/)}
					href="/settings/office"
				>
					事業者
				</Sidebar.NavGroupItemLink>

				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/settings\/users/)}
					href="/settings/users"
				>
					ユーザー
				</Sidebar.NavGroupItemLink>

				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/settings\/notifications/)}
					href="/settings/notifications"
				>
					通知
				</Sidebar.NavGroupItemLink>

				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/settings\/postpay/)}
					href="/settings/postpay"
				>
					与信限度額
				</Sidebar.NavGroupItemLink>

				{isCorporate && (
					<Sidebar.NavGroupItemLink
						current={!!pathname.match(/^\/settings\/destinations/)}
						href="/settings/destinations"
					>
						カード送付先
					</Sidebar.NavGroupItemLink>
				)}

				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/settings\/integrations/)}
					href="/settings/integrations"
				>
					連携
				</Sidebar.NavGroupItemLink>
			</Sidebar.NavGroup>
		</>
	);
};
