import { Logger } from '@/components/screen/Logger';
import { BaseLayout } from '@/components/screen/layouts/BaseLayout';
import { TagManager } from '@/components/screen/tagManager';
import { AppsBanner } from '@/components/ui/AppsBanner';
import { Chatbot } from '@/components/ui/Chatbot';
import { GlobalNotification } from '@/components/ui/GlobalNotification';
import { CurrentOfficeProvider } from '@/contexts/CurrentOfficeProvider';
import { GlobalMessageProvider } from '@/contexts/GlobalMessageProvider';
import { GlobalNotificationProvider } from '@/contexts/GlobalNotificationProvider';
import { HowToUseStorylaneProvider } from '@/contexts/HowToUseStorylaneProvider';
import { RegistrationPageStateProvider } from '@/contexts/RegistrationProvider';
import { RouteEventHandler } from '@/contexts/RouteEventHandler';
import {
	CurrentIdentificationCodeDocument,
	type CurrentIdentificationCodeQuery,
	CurrentUserIdDocument,
	type CurrentUserIdQuery,
} from '@/graphql';
import { hasInvalidOfficeIdError } from '@/hooks/useApiError';
import { initializeApollo, useApollo } from '@/lib/apollo';
import { KEY_LAST_LOGIN_OFFICE } from '@/lib/cookie';
import { hideSideBar } from '@/lib/layout';
import type { AppPropsWithLayout } from '@/lib/page';
import { initDatadogRum } from '@/lib/performance/initDatadogRum';
import { skipRedirectToThisPath } from '@/lib/redirect';
import { ApolloProvider } from '@apollo/client';
import App, { type AppContext } from 'next/app';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies } from 'nookies';
import { useEffect } from 'react';
import '@/styles/globals.css';
import { RouteHistoryProvider } from '@/contexts/RouteHistoryProvider';

const MyApp = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
	const cookies = parseCookies();
	const client = useApollo(
		pageProps.initialApolloState,
		cookies[KEY_LAST_LOGIN_OFFICE],
	);
	const router = useRouter();
	const showSidebar = !hideSideBar(router.pathname);

	const getLayout =
		Component.getLayout ??
		((page) => (
			<BaseLayout sidebar={showSidebar}>
				<GlobalNotification showSidebar={showSidebar} />
				{page}
			</BaseLayout>
		));

	useEffect(() => {
		initDatadogRum();
	}, []);

	return (
		<CurrentOfficeProvider>
			<GlobalNotificationProvider>
				<GlobalMessageProvider>
					<RouteHistoryProvider>
						<RouteEventHandler>
							<ApolloProvider client={client}>
								<Logger user={pageProps.user}>
									<HowToUseStorylaneProvider>
										{/* REVIEW: This provider should be in only registration dir */}
										<RegistrationPageStateProvider>
											<TagManager
												identificationCode={pageProps.identificationCode}
											>
												{/* SSRさせないと smart app bannerが動作しないのでこの位置 */}
												<AppsBanner />

												{getLayout(<Component {...pageProps} />)}
											</TagManager>
											<Chatbot />
										</RegistrationPageStateProvider>
									</HowToUseStorylaneProvider>
								</Logger>
							</ApolloProvider>
						</RouteEventHandler>
					</RouteHistoryProvider>
				</GlobalMessageProvider>
			</GlobalNotificationProvider>
		</CurrentOfficeProvider>
	);
};

MyApp.getInitialProps = async (appContext: AppContext) => {
	const appProps = await App.getInitialProps(appContext);
	// SSR時のみ以降の処理を実行する(ブラウザ側は実行しない)
	if (typeof window !== 'undefined') return { ...appProps };

	const { ctx, Component } = appContext;
	const authNotRequired =
		Component.displayName &&
		[
			'ErrorPage',
			'Error404Page',
			'Error403Page',
			'Error500Page',
			'HealthzPage',
			'LoginPage',
			'AuthPage',
			'AuthCallbackPage',
			'VerificationPage',
			'RegisterPage',
			'CardSupportPage',
			// Escape SSO page as it is accessed by users of external services
			'SsoPage',
			// Escape WithdrawCompletePage as it is accessed by users who don't use the app
			'WithdrawCompletePage',
		].includes(Component.displayName);
	if (authNotRequired) return { ...appProps };

	const { lastLoginOffice: officeId } = parseCookies(ctx);
	const { query, cache } = initializeApollo({ ctx, officeId });
	const { pathname, asPath } = ctx;

	let user: CurrentUserIdQuery | undefined = undefined;
	try {
		user = (
			await query<CurrentUserIdQuery>({
				query: CurrentUserIdDocument,
			})
		).data;
	} catch (e) {
		const isInvalidOfficeIdError = hasInvalidOfficeIdError(e);

		// NOTE: When X-Office-Id is invalid, we delete the invalid cookie and make redirect to /offices.
		if (isInvalidOfficeIdError) {
			destroyCookie(ctx, KEY_LAST_LOGIN_OFFICE, { path: '/' });
			ctx.res?.writeHead(302, { Location: '/offices' });
			ctx.res?.end();
		} else {
			// Redirect to login page if currentUser could not be fetched during SSR
			const loginPath = skipRedirectToThisPath(pathname)
				? '/login'
				: `/login?redirectPath=${asPath}`;
			ctx.res?.writeHead(302, { Location: loginPath });
			ctx.res?.end();
		}
	}

	// for google tag manager datalayer
	let identificationCode: CurrentIdentificationCodeQuery | undefined =
		undefined;
	try {
		identificationCode = (
			await query<CurrentIdentificationCodeQuery>({
				query: CurrentIdentificationCodeDocument,
			})
		).data;
	} catch (_e) {
		// do nothing
	}

	return {
		...appProps,
		pageProps: {
			...appProps.pageProps,
			initialApolloState: cache.extract(),
			user,
			identificationCode,
		},
	};
};

export default MyApp;
