import { yup } from '@/lib/yup';

export type LastNameKanaData = yup.Asserts<typeof lastNameKanaSchema>;

export const lastNameKanaSchema = yup.object({
	lastNameKana: yup
		.string()
		.nameKana()
		.trim()
		.max(128, '128文字以内で入力してください')
		.required(),
});
