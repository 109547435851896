import { Button } from '@/components/new/Button';
import { SelectBox } from '@/components/new/SelectBox';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { InputGroup } from '@/components/ui/inputs/InputGroup/InputGroup';
import { useAddressesLazyQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { PrefectureSelectOptions } from '@/lib/prefecture';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { AddressData } from './schema';

const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
`;

export const AddressInputs = (): JSX.Element => {
	const {
		register,
		formState: { errors },
		control,
		getValues,
		setValue,
		trigger,
		watch,
	} = useFormContext<AddressData>();
	const { handleQueryError } = useApiError();
	const [search] = useAddressesLazyQuery({
		onError: handleQueryError,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleAddressSearch = useCallback(async () => {
		const valid = await trigger('postalCode');

		if (!valid) return;

		const postalCode = getValues('postalCode');
		const res = await search({
			variables: {
				postcode: postalCode,
			},
		});
		if (res.data && res.data.addresses.length > 0) {
			// TODO: 2つ以上ある場合はモーダルを表示
			const address = res.data.addresses[0];
			setValue('prefectureCode', address.prefectureCode);
			setValue('city', address.city);
			setValue('street', address.town + (address.street ?? ''));
			setValue('buildingName', '');
		}
	}, [getValues('postalCode')]);
	return (
		<AddressWrapper>
			<InputGroup>
				<div>
					<TextField
						aria-label="postalCode"
						{...register('postalCode')}
						placeholder="郵便番号"
					/>
					{errors.postalCode && (
						<FormError>{errors.postalCode.message}</FormError>
					)}
				</div>
				<div>
					<Button type="button" variant="outline" onClick={handleAddressSearch}>
						郵便番号から住所を表示
					</Button>
				</div>
			</InputGroup>
			<InputGroup>
				<div>
					<Controller
						control={control}
						name={'prefectureCode'}
						render={({ field }) => (
							<SelectBox
								{...field}
								aria-label="prefectureCode"
								options={PrefectureSelectOptions}
								// @ts-ignore: type of [Option|undefined] but has to accept null to reset
								value={
									watch('prefectureCode')
										? PrefectureSelectOptions.find(
												(option) => option.value === field.value,
											)
										: undefined
								}
								onValueChange={(value) => field.onChange(value)}
								placeholder="都道府県"
								selectedTestId="selected-prefectureCode"
								ariaControlsId="prefectureCode"
							/>
						)}
					/>
					{errors.prefectureCode && (
						<FormError>{errors.prefectureCode.message}</FormError>
					)}
				</div>
				<div>
					<TextField
						aria-label="city"
						{...register('city')}
						placeholder="市区町村"
					/>
					{errors.city && <FormError>{errors.city.message}</FormError>}
				</div>
			</InputGroup>
			<div>
				<TextField
					aria-label="street"
					{...register('street')}
					placeholder="町名・番地"
				/>
				{errors.street && <FormError>{errors.street.message}</FormError>}
			</div>
			<div>
				<TextField
					aria-label="buildingName"
					{...register('buildingName')}
					placeholder="建物名・部屋番号（任意）"
				/>
				{errors.buildingName && (
					<FormError>{errors.buildingName.message}</FormError>
				)}
			</div>
		</AddressWrapper>
	);
};
