import { Button } from '@/components/new/Button';
import { useGoToStepsOrConfirmation } from '@/hooks/registration';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	display: 'inline-block',
	marginRight: '16px',
	'> button': {
		width: '160px',
	},
});

export const Back = (): JSX.Element => {
	const goToStepsOrConfirmation = useGoToStepsOrConfirmation();

	return (
		<ButtonWrapper>
			<Button
				variant="outline"
				size="large"
				type="button"
				onClick={goToStepsOrConfirmation}
			>
				戻る
			</Button>
		</ButtonWrapper>
	);
};
