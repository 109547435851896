import styles from './styles.module.css';

type RootProps = {
	children?: React.ReactNode;
};
const Root = ({ children }: RootProps): JSX.Element => {
	return <header className={styles.header}>{children}</header>;
};

const Menu = ({ children }: { children?: React.ReactNode }): JSX.Element => (
	<div className={styles.menu}>{children}</div>
);
Menu.displayName = 'ResponsiveHeader.Menu';

const Name = ({ children }: { children?: React.ReactNode }): JSX.Element => (
	<div className={styles.name}>{children}</div>
);
Name.displayName = 'ResponsiveHeader.Name';

const Item = ({ children }: { children?: React.ReactNode }): JSX.Element => (
	<div className={styles.item}>{children}</div>
);
Item.displayName = 'ResponsiveHeader.Item';

export const ResponsiveHeader = Object.assign(
	{},
	{
		Root,
		Menu,
		Name,
		Item,
	},
);
