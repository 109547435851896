import { type ReactNode, forwardRef, useEffect, useState } from 'react';
import styles from './styles.module.css';

const CrossIcon = () => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
	<svg
		width="15"
		height="15"
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-label="hidden"
	>
		<path
			d="M15 0.714286L14.2857 0L7.50003 6.78568L0.714355 8.70763e-06L6.93137e-05 0.714294L6.78574 7.49997L0 14.2857L0.714286 15L7.50003 8.21426L14.2858 15L15.0001 14.2857L8.21432 7.49997L15 0.714286Z"
			fill="white"
		/>
	</svg>
);

type NotificationProps = {
	color?: 'success' | 'warning' | 'error';
	children: ReactNode;
	align?: 'center' | 'left' | 'right';
	isOpen?: boolean;
	showSidebar?: boolean;
	onClickClose?: () => void;
};

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
	(
		{
			color = 'success',
			children,
			align,
			isOpen = false,
			showSidebar = true,
			onClickClose,
			...rest
		},
		ref,
	) => {
		const [isActive, setIsActive] = useState(isOpen);
		const handleCloseClick = () => {
			onClickClose?.();
			setIsActive(false);
		};

		useEffect(() => {
			setIsActive(isOpen);
		}, [isOpen]);

		if (!isActive) {
			return null;
		}

		return (
			<div
				ref={ref}
				role="alert"
				{...rest}
				className={`${styles.notification} ${styles[color]} ${
					showSidebar ? styles.showSidebar : styles.hideSidebar
				}`}
			>
				<div className={styles.inner}>
					<div className={`${styles.content} ${styles[align ?? 'left']}`}>
						{children}
					</div>
					<div className={styles.action}>
						<button
							className={styles.closeButton}
							onClick={handleCloseClick}
							aria-label="閉じる"
							type="button"
						>
							<CrossIcon />
						</button>
					</div>
				</div>
			</div>
		);
	},
);

Notification.displayName = 'Notification';
