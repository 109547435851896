import { tenantUserSchema } from '@/components/ui/inputs/TenantUser/schema';
import { yup } from '@/lib/yup';

const MAXIMUM_MONTHLY_LIMIT = 1000000000;
const MAXIMUM_PAYMENT_LIMIT = 100000000;

export type RegistrationFirstCardLimitsData = yup.Asserts<
	typeof registrationFirstCardLimitsSchema
>;

export const registrationFirstCardLimitsSchema = yup
	.object({
		maximumMonthlyLimit: yup
			.number()
			.positive('0円より大きい金額を入力してください')
			.max(MAXIMUM_MONTHLY_LIMIT, '1,000,000,000円を超える金額は設定できません')
			.typeError('金額を入力してください')
			.stripCommas()
			.required('金額を入力してください'),
		maximumPaymentLimit: yup
			.number()
			.positive('0円より大きい金額を入力してください')
			.test('lessThanLimit', function (value, { createError }) {
				const { maximumMonthlyLimit } = this.parent;
				const isLessThanMaximumLimit =
					maximumMonthlyLimit < MAXIMUM_PAYMENT_LIMIT;
				const maxValue = isLessThanMaximumLimit
					? maximumMonthlyLimit
					: MAXIMUM_PAYMENT_LIMIT;
				const maxMessage = isLessThanMaximumLimit
					? '月ごとの上限金額より小さい金額を入力してください'
					: '100,000,000円を超える金額は設定できません';

				if (!value)
					return createError({
						message: '金額を入力してください',
					});

				return value <= maxValue || createError({ message: maxMessage });
			})
			.typeError('金額を入力してください')
			.stripCommas()
			.required('金額を入力してください'),
	})
	.concat(tenantUserSchema);
