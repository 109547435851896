import { useLogger } from '@/hooks/useLogger';
import { skipRedirectToThisPath } from '@/lib/redirect';
import {
	type ApolloError,
	type ServerError,
	isApolloError,
} from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useLogout } from '.';
import { useGlobalMessage } from './useGlobalMessage';
import { useGlobalNotification } from './useGlobalNotification';

const QueryErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #ffffff;
`;

const Message = styled.span`
  color: inherit;
`;

// NOTE: Dare to use `a` tag so that it allow the page to reload.
const ReloadLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const Content = ({ message }: { message: string }): JSX.Element => (
	<QueryErrorWrapper>
		<Message>{message}</Message>
		<ReloadLink href="">再読み込み</ReloadLink>
	</QueryErrorWrapper>
);

export const ErrorCode = {
	// 認証系エラー
	AuthenticationError: 'AUTHENTICATION_ERROR',
	BadRequest: 'BAD_REQUEST',
	Unauthenticated: 'UNAUTHENTICATED',
	NotActivatedError: 'NOT_ACTIVATED_ERROR',
	// 権限系エラー
	AuthorizationError: 'AUTHORIZATION_ERROR',
	NotInvited: 'NOT_INVITED',
	// データ更新系エラー
	NotFound: 'NOT_FOUND',
	RecordInvalid: 'RECORD_INVALID',
	RecordNotDestroyed: 'RECORD_NOT_DESTROYED',
	// その他エラー
	ServiceUnavailable: 'SERVICE_UNAVAILABLE',
	InternalServerError: 'INTERNAL_SERVER_ERROR',
};

const badRequestRedirectToOffice = (path: string) => {
	return skipRedirectToThisPath(path)
		? '/offices'
		: {
				pathname: '/offices',
				query: {
					redirectPath: path,
				},
			};
};

const unauthenticatedRedirectToLogin = (path: string) => {
	return path === '/'
		? '/login'
		: {
				pathname: '/login',
				query: {
					redirectPath: path,
				},
			};
};

const unauthenticatedRedirectToOffice = (path: string) => {
	return path === '/'
		? '/offices'
		: {
				pathname: '/offices',
				query: {
					redirectPath: path,
				},
			};
};

export const getGraphQLError = (e: unknown) => {
	if (!(e instanceof Error)) {
		return undefined;
	}
	if (!isApolloError(e)) {
		return undefined;
	}
	const graphQLError = e.graphQLErrors[0];
	if (!graphQLError) {
		return undefined;
	}
	return graphQLError;
};

export const hasInvalidOfficeIdError = (e: unknown) => {
	const graphQLError = getGraphQLError(e);
	if (!graphQLError) return false;
	const isUnauthenticatedError =
		graphQLError.extensions?.code === ErrorCode.Unauthenticated;
	const isInvalidOfficeIdError = graphQLError.message.includes(
		'office_id is invalid',
	);
	return isUnauthenticatedError && isInvalidOfficeIdError;
};

export const hasOfficeNotFoundError = (e: unknown) => {
	const graphQLError = getGraphQLError(e);
	if (!graphQLError) return false;
	return graphQLError.extensions?.code === ErrorCode.NotFound;
};

export const hasNotInvitedError = (e: unknown) => {
	const graphQLError = getGraphQLError(e);
	if (!graphQLError) return false;
	return graphQLError.extensions?.code === ErrorCode.NotInvited;
};

export const hasAuthorizationError = (e: unknown) => {
	const graphQLError = getGraphQLError(e);
	if (!graphQLError) return false;
	return graphQLError.extensions?.code === ErrorCode.AuthorizationError;
};

export const hasInternalServerError = (e: unknown) => {
	const graphQLError = getGraphQLError(e);
	if (!graphQLError) return false;
	return graphQLError.extensions?.code === ErrorCode.InternalServerError;
};

export const useApiError = (): {
	handleQueryError: (error: ApolloError) => void;
	handleMutationError: (error: ApolloError) => void;
	handleCommonError: (error: ApolloError) => void; // for test
} => {
	const router = useRouter();
	const { logout } = useLogout();
	const { setErrorMessage } = useGlobalMessage();
	const { showErrorNotification } = useGlobalNotification();
	const { logCritical, logError, logInfo } = useLogger();

	const conditionalLogInfo = (condition: boolean, ...error: unknown[]) => {
		if (condition) {
			logInfo(...error);
		}
	};

	const networkErrorProcess = (error: ServerError): boolean => {
		switch (error.statusCode) {
			case 500:
				logCritical(error);
				router.push('/500');
				return true;
			case 403:
				// wafに引っかかった時に返ってくる
				logCritical(error);
				setErrorMessage(
					'無効なリクエストです。繰り返し同様の操作をしても改善されない場合、ヘルプメニュー内「お問い合わせフォーム」からお問い合わせください。',
				);

				return true;
			default:
				// queryやmutationの方で処理するので何もしない
				return false;
		}
	};

	// この関数内でエラーハンドリングが完了すればtrueを返す
	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleCommonError = useCallback((apolloError: ApolloError): boolean => {
		const { graphQLErrors, networkError } = apolloError;

		if (graphQLErrors.length > 0) {
			const { message, extensions } = graphQLErrors[0];
			switch (extensions?.code) {
				case ErrorCode.AuthenticationError:
					// not set user token
					logInfo(message, extensions?.code);
					logout();
					return true;
				case ErrorCode.BadRequest:
					if (!message.includes('X-OFFICE-ID is missing')) {
						setErrorMessage(message);
						return true;
					}

					// not set office id
					conditionalLogInfo(
						router.pathname !== '/login/redirect',
						message,
						extensions.code,
					);

					router.push(badRequestRedirectToOffice(router.pathname));

					return true;
				case ErrorCode.Unauthenticated:
					// invalid user token
					logInfo(message, extensions?.code);

					if (message.includes('access_token is invalid')) {
						router.push(unauthenticatedRedirectToLogin(router.pathname));
					}
					// invalid office id
					if (message.includes('office_id is invalid')) {
						router.push(unauthenticatedRedirectToOffice(router.pathname));
					}
					return true;
				case ErrorCode.NotActivatedError:
					// not agree terms
					logInfo(message, extensions?.code);
					router.push('/registration/terms');
					return true;
				case ErrorCode.ServiceUnavailable:
					logInfo(message, extensions?.code);
					router.push('/500');
					return true;
				case ErrorCode.InternalServerError:
					// API側で意図しないエラー
					logError(message, extensions?.code);
					showErrorNotification(
						message ||
							'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。',
					);

					return true;
				default:
					// queryやmutationの方で処理するので何もしない
					return false;
			}
		}

		if (networkError) {
			const error = networkError as ServerError;
			return networkErrorProcess(error);
		}

		return false;
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleQueryError = useCallback((apolloError: ApolloError) => {
		const alreadyHandleError = handleCommonError(apolloError);
		if (alreadyHandleError) {
			return;
		}

		// メッセージを表示しつつ内容は表示することになるのでLoadingを返すことができない
		const { graphQLErrors, networkError } = apolloError;
		const fixedPhrase =
			'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。';

		// handleCommonErrorで対処していないエラーを記録する
		if (graphQLErrors.length > 0) {
			const { message, extensions } = graphQLErrors[0];
			logError(message, extensions?.code);
		} else if (networkError) {
			logError(networkError);
		}

		const message =
			graphQLErrors.length > 0
				? graphQLErrors[0].message || fixedPhrase
				: fixedPhrase;

		showErrorNotification(<Content message={message} />);
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleMutationError = useCallback((apolloError: ApolloError) => {
		const alreadyHandleError = handleCommonError(apolloError);
		if (alreadyHandleError) {
			return;
		}

		const { graphQLErrors, networkError } = apolloError;
		if (graphQLErrors.length > 0) {
			const { message, extensions } = graphQLErrors[0];
			switch (extensions?.code) {
				case ErrorCode.AuthorizationError:
					// 事業者ログイン時のサービス利用開始権限エラー
					logInfo(message, extensions?.code);
					setErrorMessage(message);
					break;
				case ErrorCode.NotInvited: {
					// 事業者ログイン時の招待されていないエラー
					const staticMessage =
						'事業者に招待されていません。管理者にお問い合わせください。';
					logInfo(staticMessage, extensions?.code);
					setErrorMessage(message || staticMessage);
					break;
				}
				case ErrorCode.NotFound:
					// データ更新時に更新先データがないエラー
					logError(message, extensions?.code);
					setErrorMessage(
						message ||
							'更新対象が見つかりません。画面を再読み込みして頂くか、再度時間を置いてお試しください。',
					);

					break;
				case ErrorCode.RecordInvalid:
					// データ更新時のバリデーションエラー
					logError(message, extensions?.code);
					setErrorMessage(
						message ||
							'入力内容に無効な値が見つかりました。修正頂いた後に再度お試しください。',
					);
					break;
				case ErrorCode.RecordNotDestroyed:
					// 削除できないものを削除しようとしているときのエラー
					logError(message, extensions?.code);
					setErrorMessage(
						message ||
							'削除に失敗しました。繰り返し同様の操作をしても改善されない場合、ヘルプメニュー内「お問い合わせフォーム」からお問い合わせください。',
					);
					break;
				default:
					logError(message, extensions?.code);
					setErrorMessage(
						message ||
							'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。',
					);
			}
		} else if (networkError) {
			const error = networkError as ServerError;
			logError(networkError);
			if (error.statusCode === 400) {
				// バリデーションエラーがある時に返ってくる時がある
				setErrorMessage(
					'入力内容に無効な値が見つかりました。修正頂いた後に再度お試しください。',
				);
			} else {
				setErrorMessage(
					'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。',
				);
			}
		}
	}, []);

	return { handleQueryError, handleMutationError, handleCommonError };
};
