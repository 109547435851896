import { Text } from '@/components/new/Text';
import styled from 'styled-components';

type Props = {
	children: React.ReactNode;
	className?: string;
};

const ErrorText = styled(Text)`
  color: #ec4949;
`;

export const FormError = ({ children, className }: Props): JSX.Element => (
	<ErrorText size="small" className={className}>
		{children}
	</ErrorText>
);
