import styled from 'styled-components';
import { Backlink } from './BackLink';
import { Item } from './Item';
import { Title } from './Title';

const StyledNavigationBar = styled.div`
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
  display: flex;
  align-items: center;
  padding: 8px 16px;
`;

export type NavigationBarProps = {
	children: React.ReactNode;
};

const NavigationBarRoot = ({ children }: NavigationBarProps): JSX.Element => (
	<StyledNavigationBar>{children}</StyledNavigationBar>
);

export const NavigationBar = Object.assign(NavigationBarRoot, {
	Backlink,
	Title,
	Item,
});
