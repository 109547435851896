import styled from 'styled-components';
import { GlobalMessage } from '../GlobalMessage';

const Layout = styled.div`
  padding-top: 157px;
  padding-bottom: 32px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
    width: 360px;
  }
`;

export type Props = {
	children: React.ReactNode;
};

export const LoginLayout = ({ children }: Props): JSX.Element => (
	<Layout>
		<GlobalMessage />
		{children}
	</Layout>
);
