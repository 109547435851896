import { Dropdown, useDropdown } from '@/components/new/Dropdown';
import { Tooltip } from '@/components/new/Tooltip';
import { Image } from '@/components/ui/Image';
import { useErpSsoUrlQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import bento from '@images/icons/bento.svg';
import mfLogo from '@images/icons/mf-logo.svg';
import styled from 'styled-components';
import { MenuLink } from './MenuLink';

const IconWrapper = styled.div`
  display: inline-block;
  /* NOTE: To fix inline-block's height */
  vertical-align: top;
  width: 28px;
  height: 20px;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #d8d8d8;
`;

const LogoWrapper = styled.div`
  height: 20px;
  margin-right: 8px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: 0;
  line-height: 1;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
`;

const Icon = ({
	onClick,
}: {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}): JSX.Element => (
	<IconWrapper>
		<IconButton onClick={onClick}>
			<Image width={20} height={20} src={bento} />
		</IconButton>
	</IconWrapper>
);

const Anchor = (): JSX.Element => {
	const { isActive, setIsActive } = useDropdown();
	const handleClick = () => setIsActive(!isActive);

	return (
		<>
			{isActive ? (
				<Icon onClick={handleClick} />
			) : (
				<Tooltip content="マネーフォワードクラウドのサービス" side="bottom">
					<Icon onClick={handleClick} />
				</Tooltip>
			)}
		</>
	);
};

export const BentoMenu = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data: erpSsoData } = useErpSsoUrlQuery({
		variables: {
			redirectPath: '/home',
		},
		onError: handleQueryError,
	});
	const erpSsoUrl = erpSsoData?.currentUser.erpSsoUrl;

	return (
		<Dropdown.Root placement="left" anchor={<Anchor />}>
			<Dropdown.Block>
				<Dropdown.Item>
					<MenuLink
						href={erpSsoUrl ?? 'https://erp.moneyforward.com/home'}
						target="_blank"
					>
						<LogoWrapper>
							<Image src={mfLogo} width={20} height={20} />
						</LogoWrapper>
						すべてのサービスを見る
					</MenuLink>
				</Dropdown.Item>
			</Dropdown.Block>
		</Dropdown.Root>
	);
};
