import { yup } from '@/lib/yup';
import { manualPinSchema } from '../inputs/ManualPin/schema';

export type RegistrationFirstCardOptionsData = yup.Asserts<
	typeof registrationFirstCardOptionsSchema
>;

export const registrationFirstCardOptionsSchema = yup
	.object({
		officeCardName: yup.string().trim().officeCardName().ensure(),
	})
	.concat(manualPinSchema);
