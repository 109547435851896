import { Button } from '@/components/new/Button';
import { forwardRef } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	display: 'inline-block',
	marginRight: '16px',
	'> button': {
		width: '160px',
	},
});

const Activate = forwardRef<HTMLButtonElement>((props, ref) => {
	return (
		<ButtonWrapper>
			<Button type="submit" size="large" ref={ref} {...props}>
				有効化する
			</Button>
		</ButtonWrapper>
	);
});

Activate.displayName = 'RegistrationButtons.Activate';

export { Activate };
