import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import styled from 'styled-components';

const Title = styled(Text)`
  margin-right: 8px;
`;

export const LabelType = {
	OPTIONAL: 'OPTIONAL',
	REQUIRED: 'REQUIRED',
};

export type Props = {
	title: React.ReactNode;
	labelType?: 'optional' | 'required';
	children: React.ReactNode;
};

export const RegistrationTableRow = ({
	title,
	labelType,
	children,
}: Props): JSX.Element => {
	const renderLabel = (type: Props['labelType']) => {
		switch (type) {
			case 'required':
				return <StatusLabel color="red">必須</StatusLabel>;
			case 'optional':
				return <StatusLabel color="gray">任意</StatusLabel>;
			default:
				return null;
		}
	};
	return (
		<Table.Row>
			<Table.RowHeaderCell width="196px">
				<Title>{title}</Title>
				{renderLabel(labelType)}
			</Table.RowHeaderCell>
			<Table.Cell>{children}</Table.Cell>
		</Table.Row>
	);
};
