type Props = {
	color?: string;
};
export const PlusIcon = ({ color }: Props) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.66634 5.66683H5.66634V9.66683H4.33301V5.66683H0.333008V4.3335H4.33301V0.333496H5.66634V4.3335H9.66634V5.66683Z"
				fill={color ?? '#fff'}
			/>
		</svg>
	);
};
