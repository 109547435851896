import { useNationalitiesQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { convertToNationalityOptions } from '@/lib/nationality';
import { Select } from '@moneyforward/cloud-react-ui';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '../../FormError';
import type { NationalityIdSchemaData } from './schema';

export const NationalityInput = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext<NationalityIdSchemaData>();

	const { handleQueryError } = useApiError();

	const { data, loading } = useNationalitiesQuery({
		onError: handleQueryError,
	});

	const nationalities = data?.nationalities ?? [];

	const nationalityOptions = useMemo(
		() => convertToNationalityOptions(nationalities),
		[nationalities],
	);
	if (loading) return null;
	return (
		<>
			<Controller
				control={control}
				name={'nationalityId'}
				render={({ field }) => (
					<Select
						{...field}
						aria-label={'国籍'}
						instanceId={'nationalityId'}
						placeholder="選択してください"
						options={nationalityOptions}
						value={nationalityOptions.find(
							(option) => option.value === field.value,
						)}
						onChange={(e) => field.onChange(e?.value ?? '')}
					/>
				)}
			/>
			{errors.nationalityId && (
				<FormError>{errors.nationalityId.message}</FormError>
			)}
		</>
	);
};
