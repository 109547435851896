import type { CurrentIdentificationCodeQuery } from '@/graphql';
import { type DataLayerObject, dataLayerPush } from '@/lib/gtm';
import { useState } from 'react';

export const useTrackingData = (): {
	trackingData: DataLayerObject;
	setTrackingData: (data: CurrentIdentificationCodeQuery) => void;
} => {
	const [data, setData] = useState<DataLayerObject>({});

	const setTrackingData = ({
		currentUser,
		currentOffice,
	}: CurrentIdentificationCodeQuery): void => {
		setData((prevState) => {
			const current = {
				...prevState,
				user_id: currentUser.identificationCode || '',
				office_identification_code: currentOffice.identificationCode,
				office_type: currentOffice.tenantType,
			};

			dataLayerPush(current);
			return current;
		});
	};

	return { trackingData: data, setTrackingData };
};
