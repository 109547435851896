import { Image } from '@/components/ui/Image';
import { Sidebar } from '@/components/ui/Sidebar';
import IconCard from '@images/icons/sideCard.svg';
import IconGear from '@images/icons/sideGear.svg';
import IconReceiptLong from '@images/icons/sideReceiptLong.svg';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const NavItemText = styled.span`
  margin-left: 8px;
  position: relative;
  top: -1px;
`;

export const EmployeeSidebarItems = (): JSX.Element => {
	const { pathname } = useRouter();

	return (
		<>
			<Sidebar.NavGroup
				label="取引"
				imageSrc={IconReceiptLong}
				current={!!pathname.match(/^\/cards\/transactions/)}
			>
				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/cards\/transactions/)}
					href="/cards/transactions"
				>
					利用明細
				</Sidebar.NavGroupItemLink>
			</Sidebar.NavGroup>
			<Sidebar.NavItemLink
				href="/cards"
				current={!!pathname.match(/^\/cards(?!\/transactions)/)}
			>
				<Image src={IconCard} alt="カード" width={24} height={24} />
				<NavItemText>カード</NavItemText>
			</Sidebar.NavItemLink>
			<Sidebar.NavGroup
				label="設定"
				imageSrc={IconGear}
				current={!!pathname.match(/^\/settings\//)}
			>
				<Sidebar.NavGroupItemLink
					current={!!pathname.match(/^\/settings\/notifications/)}
					href="/settings/notifications"
				>
					通知
				</Sidebar.NavGroupItemLink>
			</Sidebar.NavGroup>
		</>
	);
};
