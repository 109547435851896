export const fontSize = {
	xSmall: '10px',
	small: '12px',
	middle: '13px',
	large: '14px',
	xlarge: '16px',
	xxlarge: '18px',
	xxxLarge: '20px',
	xxxxLarge: '24px',
};

export const fontColor = {
	default: '#333333',
	notes: '#999999',
	inherit: 'inherit',
};
