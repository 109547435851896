import { Prefecture } from '@/graphql';
import { yup } from '@/lib/yup';

export type AddressData = yup.Asserts<typeof addressSchema>;

export const addressSchema = yup.object({
	buildingName: yup.string().nullable().default(null).max(50),
	city: yup.string().required().max(20),
	postalCode: yup.string().postalCode().required(),
	prefectureCode: yup
		.mixed<Prefecture>()
		.oneOf(Object.values(Prefecture), '選択してください')
		.required('選択してください'),
	street: yup.string().max(25).required(),
});
