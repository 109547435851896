import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { useFormContext } from 'react-hook-form';
import type { LastNameKanaData } from './schema';

export const LastNameKanaInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<LastNameKanaData>();

	return (
		<div>
			<TextField
				aria-label="lastNameKana"
				{...register('lastNameKana')}
				placeholder="例）ヤマダ"
			/>
			{errors.lastNameKana && (
				<FormError>{errors.lastNameKana.message}</FormError>
			)}
		</div>
	);
};
