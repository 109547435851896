import type { TransactionAttachment } from '@/graphql';
import { useLogger } from '@/hooks/useLogger';
import { useEffect, useState } from 'react';

type ReturnType = {
	src: string | null;
	title: string | null;
} | null;

export const useDownloadFile = (
	data: TransactionAttachment | null | undefined,
): {
	data: ReturnType;
	loading: boolean;
	error: string | null;
} => {
	const { logError } = useLogger();
	const [returnData, setReturnData] = useState<ReturnType>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		// init value(remove cache)
		setReturnData(null);

		if (!data) return;

		setLoading(true);
		const url = data?.presignedUrlResponse.url;
		const headers = {
			'x-amz-server-side-encryption-customer-algorithm':
				data?.presignedUrlResponse.headers
					.xAmzServerSideEncryptionCustomerAlgorithm,
			'x-amz-server-side-encryption-customer-key':
				data?.presignedUrlResponse.headers.xAmzServerSideEncryptionCustomerKey,
			'x-amz-server-side-encryption-customer-key-MD5':
				data?.presignedUrlResponse.headers
					.xAmzServerSideEncryptionCustomerKeyMd5,
		};
		fetch(url, { headers })
			.then((res) => {
				if (!res.ok) {
					logError(res);
					return Promise.reject(new Error(`could not get ${data.fileName}`));
				}

				return res.blob();
			})
			.then((blob) => {
				return URL.createObjectURL(blob);
			})
			.then((dataURL) => {
				setReturnData({
					src: dataURL,
					title: data.fileName,
				});
			})
			.catch((e) => {
				setError((e as Error).message);
			})
			.finally(() => {
				setLoading(false);
			});

		return () => URL.revokeObjectURL(returnData?.src ?? '');
	}, [data]);

	return { data: returnData, loading, error };
};
