import { forwardRef } from 'react';
import { useDropdown } from '../DropdownContext';
import styles from './styles.module.css';

type Props = {
	children?: React.ReactNode;
	width?: string;
	placement?: 'left' | 'right';
};

export const DropdownBody = forwardRef<HTMLDivElement, Props>(
	({ width, ...props }, ref) => {
		const { isActive } = useDropdown();
		const classNames = [styles.body, props.placement && styles[props.placement]]
			.filter(Boolean)
			.join(' ');
		return (
			<div
				ref={ref}
				className={classNames}
				style={{ width }}
				aria-hidden={!isActive}
				{...props}
			/>
		);
	},
);

DropdownBody.displayName = 'Dropdown.Body';
