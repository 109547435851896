import { Header as UiHeader } from '@/components/new/Header';
import { Image } from '@/components/ui/Image';
import logo from '@images/logo.svg';
import styled from 'styled-components';
import { BentoMenu } from './BentoMenu';
import { HelpMenu } from './HelpMenu';
import { NewsMenu } from './NewsMenu';
import { OfficeMenu } from './OfficeMenu';
import { UserMenu } from './UserMenu';

const Anchor = styled.a`
  display: inline-flex;
  text-decoration: none;
`;

export const Header = (): JSX.Element => {
	return (
		<UiHeader.Root>
			<UiHeader.Name>
				<BentoMenu />
				<Anchor href="/">
					<Image
						src={logo}
						width={238}
						height={20}
						alt="Money Forward Pay for Business"
					/>
				</Anchor>
			</UiHeader.Name>
			<UiHeader.Item>
				<NewsMenu />
				<HelpMenu />
				<OfficeMenu />
				<UserMenu />
			</UiHeader.Item>
		</UiHeader.Root>
	);
};
