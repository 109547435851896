import { Link } from '@/components/new/Link';
import { useNavisSsoLinkQuery } from '@/graphql';
import { useLogger } from '@/hooks/useLogger';

type NavisSsoLinkProps = {
	linkText?: string;
	link: URL;
};

export const getPathname = (url: URL) => url.pathname;

export const NavisSsoLink = ({
	linkText = '管理コンソール',
	link,
}: NavisSsoLinkProps): JSX.Element => {
	const { logError } = useLogger();

	const redirectPath = getPathname(link);
	const { data, loading, error } = useNavisSsoLinkQuery({
		variables: {
			redirectPath: redirectPath,
		},
		onError: (e) => logError(e),
	});

	if (loading || error || !data) {
		return (
			<Link href={link} target="_blank">
				{linkText}
			</Link>
		);
	}

	return (
		<Link href={data.currentUser.navisSsoUrl ?? link} target="_blank">
			{linkText}
		</Link>
	);
};
