import NextLink from 'next/link';
import styled, { css } from 'styled-components';
import { values } from './values';

const bgColor = {
	base: '#424954',
	active: '#3D79D0',
};

const textColor = {
	base: '#C2D1FC',
	active: '#FFF',
};

type StyledNavGroupItemLinkProps = {
	current: boolean;
	children: React.ReactNode;
};

const StyledNavGroupItemLink = styled.li<StyledNavGroupItemLinkProps>`
  ${({ current }) => css`
    width: 100%;
    height: ${values.navGroupItemLink.height}px;
    & > a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 12px;
      text-decoration: none;
      background-color: ${bgColor.base};
      border-left: solid 4px ${current ? bgColor.active : bgColor.base};
      color: ${current ? textColor.active : textColor.base};
      &:hover {
        border-left: solid 4px ${bgColor.active};
        color: ${textColor.active};
      }
    }
  `}
`;

type NavGroupItemLinkProps = StyledNavGroupItemLinkProps & {
	href: string;
};

export const NavGroupItemLink = ({
	current,
	href,
	children,
}: NavGroupItemLinkProps): JSX.Element => (
	<StyledNavGroupItemLink current={current}>
		<NextLink href={href} passHref>
			{/* biome-ignore lint/a11y/useValidAnchor: TODO*/}
			<a>{children}</a>
		</NextLink>
	</StyledNavGroupItemLink>
);

NavGroupItemLink.displayName = 'Sidebar.NavGroupItemLink';
