import {
	Arrow,
	Content,
	Portal,
	Provider,
	Root,
	type TooltipContentProps,
	Trigger,
} from '@radix-ui/react-tooltip';
import styles from './styles.module.css';

export type TooltipProps = {
	content: React.ReactNode;
	children: React.ReactNode;
	side?: TooltipContentProps['side'];
};

export const Tooltip = ({ content, children, side = 'top' }: TooltipProps) => (
	<Provider delayDuration={300} disableHoverableContent>
		<Root>
			<Trigger asChild>
				<div className={styles.trigger}>{children}</div>
			</Trigger>
			<Portal>
				<Content className={styles.content} side={side} arrowPadding={12}>
					{content}
					<Arrow className={styles.arrow} width={12} height={8} />
				</Content>
			</Portal>
		</Root>
	</Provider>
);
