import { ResponsiveHeader as UiResponsiveHeader } from '@/components/new/ResponsiveHeader';
import { Image } from '@/components/ui/Image';
import logo from '@images/logo.svg';
import styled from 'styled-components';
import { NavigationMenu } from './NavigationMenu';

const Anchor = styled.a`
  display: inline-flex;
  text-decoration: none;
`;

export const ResponsiveHeader = (): JSX.Element => {
	return (
		<UiResponsiveHeader.Root>
			<UiResponsiveHeader.Menu />
			<UiResponsiveHeader.Name>
				<Anchor href="/">
					<Image
						src={logo}
						width={282}
						height={24}
						alt="Money Forward Pay for Business"
					/>
				</Anchor>
			</UiResponsiveHeader.Name>
			<UiResponsiveHeader.Item>
				<NavigationMenu />
			</UiResponsiveHeader.Item>
		</UiResponsiveHeader.Root>
	);
};
