import type { Nationality } from '@/graphql';

export const getNationalityLabel = ({
	nameJa,
	nameEn,
}: Pick<Nationality, 'nameJa' | 'nameEn'>) => `${nameJa} | ${nameEn}`;

export const convertToNationalityOptions = (nationalities: Nationality[]) =>
	nationalities.map<{
		value: string;
		label: string;
	}>(({ id, nameEn, nameJa }) => ({
		value: id,
		label: getNationalityLabel({ nameJa, nameEn }),
	}));
