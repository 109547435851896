import { Radio } from '@/components/new/Radio';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { RegistrationTable } from '@/components/ui/RegistrationTable/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow/RegistrationTableRow';
import { AddressInputs } from '@/components/ui/inputs/Address/AddressInputs';
import { BirthDateInput } from '@/components/ui/inputs/BirthDate/BirthDateInput';
import { FirstNameInput } from '@/components/ui/inputs/FirstName/FirstNameInput';
import { FirstNameKanaInput } from '@/components/ui/inputs/FirstNameKana/FirstNameKanaInput';
import { InputGroup } from '@/components/ui/inputs/InputGroup/InputGroup';
import { LastNameInput } from '@/components/ui/inputs/LastName/LastNameInput';
import { LastNameKanaInput } from '@/components/ui/inputs/LastNameKana/LastNameKanaInput';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { FirstNameEnInput } from '../inputs/FirstNameEn';
import { LastNameEnInput } from '../inputs/LastNameEn';
import { MiddleNameEnInput } from '../inputs/MiddleNameEn';
import { NationalityInput } from '../inputs/Nationality';
import { ResidencePeriodEndDateInput } from '../inputs/ResidencePeriodEndDate';
import { ResidentialStatusInput } from '../inputs/ResidentialStatus';
import type { RegistrationAccountManagerDetailsData } from './schema';

const MiddleNameWrapper = styled.div`
  margin-top: 8px;
`;

const InputWrapper = styled.div`
  width: 260px;
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const TextFieldWBox = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextBox = styled.div`
  margin-bottom: 8px;
`;
export const RegistrationAccountManagerDetails = (): JSX.Element => {
	const {
		register,
		watch,
		formState: { errors },
		setValue,
	} = useFormContext<RegistrationAccountManagerDetailsData>();

	const updateAutoLastKana = (value: string) => {
		setValue('lastNameKana', value);
	};

	const updateAutoFirstKana = (value: string) => {
		setValue('firstNameKana', value);
	};

	const isCorporateRepresentative =
		watch('isCorporateRepresentative') === 'true';

	if (isCorporateRepresentative) {
		setValue('position', '');
	}

	const isJapan = watch('nationalityId') === 'JP';

	return (
		<RegistrationTable header="取引担当者情報（ご本人が入力してください）">
			<RegistrationTableRow title="役職">
				<RadioWrapper>
					<Radio {...register('isCorporateRepresentative')} value="true">
						法人の代表者
					</Radio>
					<Radio {...register('isCorporateRepresentative')} value="false">
						法人の代表者ではない
					</Radio>
				</RadioWrapper>
				{!isCorporateRepresentative && (
					<TextFieldWBox>
						<TextField
							aria-label="position"
							{...register('position')}
							placeholder="所属・役職等を入力してください"
						/>
						{errors.position && (
							<FormError>{errors.position.message}</FormError>
						)}
						<div>
							代表者以外の方が取引担当者になる場合、会社メールアドレスへの受信確認が必要です。申込後２営業日以内に取引担当者認証メールを送信します。認証が取れない場合、お電話で確認をさせていただく場合がございます。
						</div>
					</TextFieldWBox>
				)}
			</RegistrationTableRow>
			<RegistrationTableRow title="氏名">
				<TextBox>
					<Text size="small">本人確認書類と同じ情報を入力してください。</Text>
				</TextBox>
				<InputGroup>
					<LastNameInput updateValue={updateAutoLastKana} />
					<FirstNameInput updateValue={updateAutoFirstKana} />
				</InputGroup>
			</RegistrationTableRow>
			<RegistrationTableRow title="氏名（フリガナ）">
				<InputGroup>
					<LastNameKanaInput />
					<FirstNameKanaInput />
				</InputGroup>
			</RegistrationTableRow>
			<RegistrationTableRow title="氏名（英語表記）">
				<InputGroup>
					<LastNameEnInput />
					<FirstNameEnInput />
				</InputGroup>
				<MiddleNameWrapper>
					<MiddleNameEnInput />
				</MiddleNameWrapper>
			</RegistrationTableRow>
			<RegistrationTableRow title="生年月日">
				<BirthDateInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="住所">
				<TextBox>
					<Text size="small">
						本人確認書類との一致を確認します。法人所在地ではなく本人確認書類に記載されている住所を入力してください。
					</Text>
				</TextBox>
				<AddressInputs />
			</RegistrationTableRow>
			<RegistrationTableRow title="国籍">
				<NationalityInput />
			</RegistrationTableRow>
			{!isJapan && (
				<>
					<RegistrationTableRow title="在留資格">
						<InputWrapper>
							<ResidentialStatusInput />
						</InputWrapper>
					</RegistrationTableRow>
					<RegistrationTableRow title="在留期間（満了日）">
						<TextBox>
							<Text size="small">
								永住者や期限に定めのない場合は、在留カード等の「カード有効期限」を入力してください。
							</Text>
						</TextBox>
						<InputWrapper>
							<ResidencePeriodEndDateInput />
						</InputWrapper>
					</RegistrationTableRow>
				</>
			)}
		</RegistrationTable>
	);
};
