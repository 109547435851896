import NextLink, { type LinkProps } from 'next/link';
import type { PropsWithChildren } from 'react';
import styles from './styles.module.css';

type Props = PropsWithChildren<LinkProps> & {
	target?: string;
};

export const MenuLink = ({ target, children, ...rest }: Props) => (
	<NextLink {...rest} passHref>
		<a {...{ target }} className={styles.anchor}>
			{children}
		</a>
	</NextLink>
);
