import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { ResidentialStatusSelectOptions } from '@/lib/residentialStatus';
import { Controller, useFormContext } from 'react-hook-form';
import type { ResidentialStatusSchemaData } from './schema';

export const ResidentialStatusInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<ResidentialStatusSchemaData>();

	return (
		<>
			<Controller
				control={control}
				name="residentialStatus"
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="在留資格"
						placeholder="選択してください"
						options={ResidentialStatusSelectOptions}
						value={ResidentialStatusSelectOptions.find(
							(option) => option.value === field.value,
						)}
						onValueChange={(value) => field.onChange(value)}
						selectedTestId="selected-residentialStatus"
						ariaControlsId="residentialStatus"
					/>
				)}
			/>
			{errors.residentialStatus && (
				<FormError>{errors.residentialStatus.message}</FormError>
			)}
		</>
	);
};
