import { forwardRef } from 'react';
import styled from 'styled-components';
import { Activate } from './Activate';
import { Back } from './Back';
import { Cancel } from './Cancel';
import { Invite } from './Invite';
import { Save } from './Save';
import { ToActivate } from './ToActivate';
import { Upload } from './Upload';

type Pattern =
	| 'cancelAndSave'
	| 'back'
	| 'toActivate'
	| 'activate'
	| 'invite'
	| 'upload';

export type Props = {
	pattern?: Pattern;
};

const ButtonWrapper = styled.div`
  text-align: center;
`;
const Box = styled.div`
  margin-top: 24px;
`;

const RegistrationButtons = forwardRef<HTMLButtonElement, Props>(
	({ pattern = 'cancelAndSave' }, ref) => {
		const renderButton = (pattern: string) => {
			switch (pattern) {
				case 'back':
					return <Back />;
				case 'toActivate':
					return <ToActivate />;
				case 'activate':
					return <Activate ref={ref} />;
				case 'invite':
					return <Invite />;
				case 'upload':
					return (
						<>
							<Cancel />
							<Upload />
						</>
					);
				default:
					return (
						<>
							<Cancel />
							<Save />
						</>
					);
			}
		};

		return (
			<Box>
				<ButtonWrapper>{renderButton(pattern)}</ButtonWrapper>
			</Box>
		);
	},
);

RegistrationButtons.displayName = 'RegistrationButtons';

export { RegistrationButtons };
