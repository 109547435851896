import { ChevronIcon } from '@/components/new/icon/ChevronIcon';
import { Image } from '@/components/ui/Image';
import type { ImageProps } from 'next/image';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { values } from './values';

const bgColor = {
	base: '#313740',
	active: '#3D79D0',
};

const textColor = {
	base: '#C2D1FC',
	active: '#FFF',
};

type ButtonProps = {
	current: boolean;
};

const Button = styled.button<ButtonProps>`
  ${({ current }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    background-color: ${current ? bgColor.active : bgColor.base};
    color: ${current ? textColor.active : textColor.base};
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;

    &:hover {
      background-color: ${bgColor.active};
      color: ${textColor.active};
    }

    svg {
      color: ${current ? textColor.active : textColor.base};

      &:hover {
        color: ${current ? textColor.active : textColor.base};
      }
    }
  `}
`;

const Text = styled.span`
  margin-left: 8px;
  margin-right: auto;
  position: relative;
  top: -1px;
`;

type NavListProps = {
	expanded: boolean;
	listHeight: number;
};

const NavList = styled.ul<NavListProps>`
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  ${({ expanded, listHeight }) => css`
    max-height: ${expanded ? `${listHeight}px` : 0};
  `}
`;

type NavGroupProps = {
	label: string;
	imageSrc: ImageProps['src'];
	current: boolean;
	children: React.ReactNode;
};

export const NavGroup = ({
	current,
	label,
	imageSrc,
	children,
}: NavGroupProps): JSX.Element => {
	const [expanded, setExpanded] = useState(current);

	const listHeight =
		React.Children.count(children) * values.navGroupItemLink.height;

	return (
		<li>
			<Button
				current={current}
				onClick={() => setExpanded(!expanded)}
				aria-expanded={expanded}
			>
				<Image src={imageSrc} alt="" width={24} height={24} />
				<Text>{label}</Text>
				<ChevronIcon
					size={8}
					rotation={expanded ? 'up' : 'down'}
					color="lightblue"
				/>
			</Button>
			<NavList
				expanded={expanded}
				listHeight={listHeight}
				aria-hidden={!expanded}
			>
				{children}
			</NavList>
		</li>
	);
};

NavGroup.displayName = 'Sidebar.NavGroup';
