import { type ReactNode, createContext, useMemo, useState } from 'react';

type State = {
	message: ReactNode | string;
	type: 'success' | 'warning' | 'error';
};

export type GlobalMessageContextType = State & {
	resetGlobalMessage: () => void;
	setSuccessMessage: (message: State['message']) => void;
	setErrorMessage: (message: State['message']) => void;
	setWarningMessage: (message: State['message']) => void;
};

export const GlobalMessageContext = createContext<GlobalMessageContextType>({
	message: '',
	type: 'success',
	resetGlobalMessage: () => {
		// This is a placeholder function that will be overridden by the provider
	},
	setSuccessMessage: () => {
		// This is a placeholder function that will be overridden by the provider
	},
	setErrorMessage: () => {
		// This is a placeholder function that will be overridden by the provider
	},
	setWarningMessage: () => {
		// This is a placeholder function that will be overridden by the provider
	},
});

export const GlobalMessageProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const defaultValue: State = {
		message: '',
		type: 'success',
	};
	const [message, setMessage] = useState<State>(defaultValue);

	const value = useMemo(() => {
		return {
			...message,
			resetGlobalMessage: () => {
				setMessage(defaultValue);
			},
			setSuccessMessage: (message: State['message']) => {
				setMessage({ message, type: 'success' });
			},
			setErrorMessage: (message: State['message']) => {
				setMessage({ message, type: 'error' });
			},
			setWarningMessage: (message: State['message']) => {
				setMessage({ message, type: 'warning' });
			},
		};
	}, [message]);

	return (
		<GlobalMessageContext.Provider value={value}>
			{children}
		</GlobalMessageContext.Provider>
	);
};
