import { type ReactNode, useContext } from 'react';
import { StepsModeContext } from './Steps';
import styles from './styles.module.css';

type StepStatus = 'waiting' | 'inProgress' | 'completed';

const Title = ({
	stepStatus,
	children,
}: {
	stepStatus: StepStatus;
	children: ReactNode;
}): JSX.Element => (
	<span className={`${styles.stepTitle} ${styles[stepStatus]}`}>
		{children}
	</span>
);

const Circle = ({
	stepStatus,
	children,
}: {
	stepStatus: StepStatus;
	children: ReactNode;
}): JSX.Element => (
	<div className={`${styles.stepCircle} ${styles[stepStatus]}`}>{children}</div>
);

const Count = ({ children }: { children: ReactNode }): JSX.Element => (
	<div className={styles.stepCount}>{children}</div>
);

const Check = (): JSX.Element => (
	<svg
		viewBox="0 0 18 14"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		className={styles.stepCheck}
	>
		<title>check</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.9999 11.2L1.7999 6.99998L0.399902 8.39998L5.9999 14L17.9999 1.99998L16.5999 0.599976L5.9999 11.2Z"
		/>
	</svg>
);

export type Props = {
	step: number;
	stepStatus: StepStatus;
	title: string;
};

const Step = ({ step, title, stepStatus }: Props): JSX.Element => {
	const { mode } = useContext(StepsModeContext);

	return (
		<div className={styles.stepWrapper}>
			<Title stepStatus={stepStatus}>{title}</Title>
			<Circle stepStatus={stepStatus}>
				{mode === 'check' && stepStatus === 'completed' ? (
					<Check />
				) : (
					<Count>{step}</Count>
				)}
			</Circle>
		</div>
	);
};

export { Step };
