import { Step, type StepProps, StepTie, Steps } from '@/components/new/Steps';
import styled from 'styled-components';

export type Props = {
	completedStepCount: number;
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  margin: 60px 0;
`;

export const RegistrationProgressBar = ({
	completedStepCount = 0,
}: Props): JSX.Element => {
	const totalCount = 4;

	// Set step statuses
	const statuses: StepProps['stepStatus'][] = [
		...new Array(completedStepCount).fill('completed'),
		'inProgress',
		...new Array(totalCount).fill('waiting'),
	].slice(0, totalCount);

	return (
		<Wrapper>
			<Steps>
				<Step step={1} stepStatus={statuses[0]} title="申請内容の審査" />
				<StepTie completed={statuses[0] === 'completed'} />
				<Step step={2} stepStatus={statuses[1]} title="カード郵送" />
				<StepTie completed={statuses[1] === 'completed'} />
				<Step step={3} stepStatus={statuses[2]} title="カード有効化" />
				<StepTie completed={statuses[2] === 'completed'} />
				<Step step={4} stepStatus={statuses[3]} title="利用申込完了" />
			</Steps>
		</Wrapper>
	);
};
