export const getCreateOfficeUrl = (
	email: string,
	returnPath: string,
): string => {
	return `${process.env.NEXT_PUBLIC_NAVIS_URL}/sso?redirect_path=/app/tenant_registration/new&return_service=biz_pay&return_path=${returnPath}&login_hint=${email}&plan=web&service=biz_pay`;
};

export const addUserUrl = `${process.env.NEXT_PUBLIC_NAVIS_URL}/app/user_imports`;
export const tenantUrl = `${process.env.NEXT_PUBLIC_NAVIS_URL}/app/tenant`;
export const usersUrl = `${process.env.NEXT_PUBLIC_NAVIS_URL}/app/users`;
