import { ResidentialStatusEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type ResidentialStatusSchemaData = yup.Asserts<
	typeof residentialStatusSchema
>;

const values = Object.values(ResidentialStatusEnum);

export const residentialStatusFieldSchema = yup
	.mixed<ResidentialStatusEnum>()
	.oneOf(values, '選択してください')
	.required('選択してください')
	.test(
		'residentialStatus',
		'本サービスは事業用となりますので、お申込みを受け付けることができません。',
		(value) =>
			![
				ResidentialStatusEnum.CulturalActivities,
				ResidentialStatusEnum.TemporaryVisitor,
				ResidentialStatusEnum.Student,
				ResidentialStatusEnum.Trainee,
				ResidentialStatusEnum.Dependent,
			].includes(value),
	);

export const residentialStatusSchema = yup.object({
	residentialStatus: residentialStatusFieldSchema,
});
