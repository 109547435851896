import type { CurrentIdentificationCodeQuery } from '@/graphql';
import { gtmScript } from '@/lib/gtm';
import Script from 'next/script';
import { useEffect } from 'react';
import { useTrackingData } from './hooks';

type TagManagerProps = {
	children: React.ReactNode;
	identificationCode?: CurrentIdentificationCodeQuery;
};

export const TagManager = ({
	children,
	identificationCode,
}: TagManagerProps): JSX.Element => {
	const { setTrackingData } = useTrackingData();

	// userのtracking dataの送信
	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		// push dataLayer when window was loaded
		if (identificationCode) {
			setTrackingData(identificationCode);
		}
	}, [identificationCode]);

	return (
		<>
			{/* Google Tag Manager */}
			<Script
				id="gtmScript"
				// load script after fetch user data
				strategy="lazyOnload"
				// biome-ignore lint/security/noDangerouslySetInnerHtml: This is a script tag
				dangerouslySetInnerHTML={{
					__html: gtmScript,
				}}
			/>
			{/* End Google Tag Manager */}
			{children}
		</>
	);
};
