import { Image } from '@/components/ui/Image';
import IconClose from '@images/icons/iconClose.svg';
import IconInfo from '@images/icons/infoOutlined.svg';
import type React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 8px 8px 8px 16px;
  background: #ffffff;
  border-left: solid 4px #7c8291;
  display: flex;
  align-items: center;
`;

const IconInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 12px;
  position: relative;
  bottom: -1px;
`;

const StyledButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

type Props = {
	children: React.ReactNode;
	className?: string;
	isOpen?: boolean;
	onClickClose?: () => void;
	showCloseButton?: boolean;
};

export const InformationMessage = ({
	children,
	className,
	isOpen = true,
	onClickClose,
	showCloseButton = true,
}: Props): JSX.Element | null => {
	const [isActive, setIsActive] = useState(isOpen);

	if (!isActive) {
		return null;
	}

	const handleCloseClick = () => {
		onClickClose?.();
		setIsActive(false);
	};

	return (
		<Wrapper role="complementary" className={className}>
			<IconInfoContainer>
				<Image src={IconInfo} width={20} height={20} alt="" />
			</IconInfoContainer>

			{children}

			{showCloseButton && (
				<StyledButton onClick={handleCloseClick} type="button">
					<Image src={IconClose} width={16} height={16} alt="閉じる" />
				</StyledButton>
			)}
		</Wrapper>
	);
};
