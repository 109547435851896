import { useRollbar } from '@rollbar/react';

export const useLogger = () => {
	const rollbar = useRollbar();
	// @rollbar/reactがts未対応なのでtype指定なし
	const logCritical = (...error: unknown[]) => {
		rollbar.critical(...error);
	};

	const logError = (...error: unknown[]) => {
		rollbar.error(...error);
	};

	const logWarning = (...error: unknown[]) => {
		rollbar.warning(...error);
	};

	const logInfo = (...error: unknown[]) => {
		rollbar.info(...error);
	};

	// ログの種類が複数あるので、objectで返す
	return { logCritical, logError, logWarning, logInfo };
};
