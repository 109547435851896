import { Dropdown } from '@/components/new/Dropdown';
import { IconButton } from './IconButton';
import { MenuLink } from './MenuLink';

export const NewsMenu = (): JSX.Element => {
	return (
		<Dropdown.Root
			width="176px"
			placement="right"
			anchor={<IconButton message="お知らせ" type="news" />}
		>
			<Dropdown.Block>
				<Dropdown.ActionItem>
					<MenuLink
						href="https://biz.moneyforward.com/support/biz-pay/news/new-feature"
						target="_blank"
					>
						新機能のお知らせ
					</MenuLink>
				</Dropdown.ActionItem>
			</Dropdown.Block>
		</Dropdown.Root>
	);
};
