import { type ReactNode, forwardRef } from 'react';
import styles from './styles.module.css';

type Props = {
	color?: 'success' | 'warning' | 'error';
	children: ReactNode;
	isAnnouncement?: boolean;
};

export const FlashMessage = forwardRef<HTMLDivElement, Props>(
	({ color = 'success', isAnnouncement = false, ...rest }, ref) => (
		<div
			ref={ref}
			className={`${styles.flashMessage} ${styles[color]} ${
				isAnnouncement ? styles.announcement : ''
			}`}
			{...rest}
		/>
	),
);

FlashMessage.displayName = 'FlashMessage';
