import * as Select from '@radix-ui/react-select';
import React, { forwardRef, type PropsWithChildren } from 'react';
import { CaretIcon } from '../icon/CaretIcon';
import styles from './styles.module.css';

const SelectItem = React.forwardRef<
	HTMLDivElement,
	PropsWithChildren<Select.SelectItemProps>
>(({ children, ...props }, ref) => {
	return (
		<Select.Item {...props} ref={ref} className={styles.item}>
			<Select.ItemText>{children}</Select.ItemText>
		</Select.Item>
	);
});
SelectItem.displayName = 'SelectItem';

type Option = {
	value: string;
	label: string;
};

type Props = {
	options: Option[];
	name?: string;
	value?: Option;
	defaultValue?: Option;
	disabled?: boolean;
	invalid?: boolean;
	placeholder?: string;
	onValueChange: (option: string) => void;
	selectedTestId?: string;
	ariaControlsId?: string;
};

const SelectBox = forwardRef<HTMLButtonElement, Props>(
	(
		{
			options,
			name,
			value,
			disabled,
			invalid,
			placeholder,
			onValueChange,
			selectedTestId,
			defaultValue,
			ariaControlsId,
		},
		ref,
	) => (
		<Select.Root
			name={name}
			disabled={disabled}
			onValueChange={onValueChange}
			value={value?.value}
			defaultValue={defaultValue?.value}
		>
			<Select.Trigger
				className={styles.trigger}
				data-invalid={invalid ? '' : undefined}
				aria-controls={ariaControlsId}
				ref={ref}
			>
				<Select.Value placeholder={placeholder} data-testid={selectedTestId} />
				<Select.Icon className={styles.icon}>
					<CaretIcon size={24} rotation="down" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content className={styles.content} position="popper">
					<Select.Viewport>
						<Select.Group>
							{options.map((option) => (
								<SelectItem key={option.value} value={option.value}>
									{option.label}
								</SelectItem>
							))}
						</Select.Group>
					</Select.Viewport>
				</Select.Content>
			</Select.Portal>
		</Select.Root>
	),
);
SelectBox.displayName = 'SelectBox';

export { SelectBox };
