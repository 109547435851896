import { yup } from '@/lib/yup';

export type RegistrationFirstCardSettingsData = yup.Asserts<
	typeof registrationFirstCardSettingsSchema
>;

export const registrationFirstCardSettingsSchema = yup.object({
	foreignPermission: yup.bool().required().default(true),
	name: yup
		.string()
		// remove leading and trailing spaces
		.trim()
		// replace full width spaces
		.transform((value: string) => value.replace(/\u3000/g, ' '))
		// replace multiple spaces in between words with single space
		.transform((value: string) => value.replace(/\s{2,}/g, ' '))
		.uppercase()
		// allow only uppercase letters, digits and spaces
		.matches(
			/^[A-Z0-9 ]+$/,
			'26文字以内の半角英数字または半角スペースで入力してください',
		)
		.max(26, '26文字以内の半角英数字または半角スペースで入力してください')
		.required(),
});
