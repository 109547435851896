import { yup } from '@/lib/yup';

export type ManualPinData = yup.Asserts<typeof manualPinSchema>;

export const manualPinSchema = yup.object({
	pin: yup
		.string()
		.nullable()
		.emptyToNull()
		.halfWidthNumber()
		.length(4, '4文字の半角数字で入力してください'),
});
