import type { UrlObject } from 'node:url';
import NextLink from 'next/link';
import styled, { css } from 'styled-components';

const bgColor = {
	base: '#313740',
	active: '#3D79D0',
};

const textColor = {
	base: '#C2D1FC',
	active: '#FFF',
};

type StyledNavItemProps = {
	current?: boolean;
	children?: React.ReactNode;
};

const StyledNavItem = styled.li<StyledNavItemProps>`
  ${({ current = false }) => css`
    width: 100%;
    height: 48px;
    & > a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 12px;
      text-decoration: none;
      background-color: ${current ? bgColor.active : bgColor.base};
      color: ${current ? textColor.active : textColor.base};
      &:hover {
        background-color: ${bgColor.active};
        color: ${textColor.active};
      }
    }
  `}
`;

export type NavItemProps = StyledNavItemProps & {
	href: string | UrlObject;
};

export const NavItemLink = ({
	current,
	href,
	children,
}: NavItemProps): JSX.Element => (
	<StyledNavItem current={current}>
		<NextLink href={href} passHref>
			{/* biome-ignore lint/a11y/useValidAnchor: TODO */}
			<a>{children}</a>
		</NextLink>
	</StyledNavItem>
);

NavItemLink.displayName = 'Sidebar.NavItemLink';
