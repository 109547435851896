import Script from 'next/script';

export const Chatbot = (): JSX.Element | null => {
	return (
		<Script
			id="bedore-webagent"
			data-testid="bedore-webagent"
			src="https://webagent.bedore.jp/init.js"
			data-token="2997a3ba-7050-4ca0-916a-ad50317cf1d2"
		/>
	);
};
