import styled from 'styled-components';

const Description = styled.div`
  padding: 16px 0 24px;
  text-align: center;
  line-height: 21px;
`;

type Props = {
	children: React.ReactNode;
};

export const LoginDescription = ({ children }: Props): JSX.Element => (
	<Description>{children}</Description>
);
