import { yup } from '@/lib/yup';

export type LastNameData = yup.Asserts<typeof lastNameSchema>;

export const lastNameSchema = yup.object({
	lastName: yup
		.string()
		.name()
		.trim()
		.max(128, '128文字以内で入力してください')
		.required(),
});
