import { HowToUseStorylaneContext } from '@/contexts/HowToUseStorylaneProvider';
import { useContext } from 'react';

export const HowToUseStorylaneButton = (): JSX.Element => {
	const { showStorylane } = useContext(HowToUseStorylaneContext);
	return (
		<button type="button" onClick={showStorylane}>
			はじめての使い方ガイド
		</button>
	);
};
