import { Tooltip, type TooltipProps } from '@/components/new/Tooltip';
import { Image } from '@/components/ui/Image';
import question from '@images/icons/question.svg';
import styled from 'styled-components';

type Props = Omit<TooltipProps, 'children'> & {
	label: React.ReactNode;
};

const Wrapper = styled.span`
  display: inline-block;
`;

const Label = styled.span`
  margin-right: 4px;
`;

const IconWrapper = styled.div`
  position: relative;
  bottom: -1px;
`;

export const TextWithQuestionIcon = ({
	label,
	...rest
}: Props): JSX.Element => (
	<Wrapper>
		<Label>{label}</Label>
		<Tooltip {...rest}>
			<IconWrapper>
				<Image src={question} width={12} height={12} />
			</IconWrapper>
		</Tooltip>
	</Wrapper>
);
