import styles from './styles.module.css';

type RowProps = React.HTMLAttributes<HTMLTableRowElement> & {
	disabled?: boolean;
	selected?: boolean;
};

export const Row = ({ disabled, selected, ...rest }: RowProps): JSX.Element => {
	const isClickable = typeof rest.onClick === 'function';
	const classNames = [
		styles.row,
		isClickable && styles.clickable,
		disabled && styles.disabled,
		selected && styles.selected,
	]
		.filter(Boolean)
		.join(' ');
	return <tr className={classNames} {...rest} />;
};
